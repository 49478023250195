import { ADD_CUSTOMER_INSTALMENT_FIELD_KEYS } from "features/Crm/modules/AddCustomerModal/modules/InstalmentsField/modules/InstalmentField/InstalmentField.constants";
import { INSTALMENT_HISTORY_RECORD_KEYS } from "../../AddInstalmentPaymentModal.constants";
import { ORDER_PAYMENT_TYPE } from "schedule-v2/constants";

export const getFormInstalmentDataFromInstalmentHistory = (
  instalmentHistory
) => {
  const {
    [INSTALMENT_HISTORY_RECORD_KEYS.installment_uuid]: instalmentHistoryUuid,
    [INSTALMENT_HISTORY_RECORD_KEYS.price]: instalmentHistoryPrice,
    [INSTALMENT_HISTORY_RECORD_KEYS.is_paid]: isInstalmentPaid,
  } = instalmentHistory;

  return {
    [ADD_CUSTOMER_INSTALMENT_FIELD_KEYS.installment_uuid]:
      instalmentHistoryUuid,
    [ADD_CUSTOMER_INSTALMENT_FIELD_KEYS.installment_price]:
      instalmentHistoryPrice,
    [ADD_CUSTOMER_INSTALMENT_FIELD_KEYS.payment_type]:
      ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS,
    [INSTALMENT_HISTORY_RECORD_KEYS.is_paid]: isInstalmentPaid,
  };
};
