import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { ADD_CUSTOMER_APIS } from "features/Crm/modules/AddCustomerModal/constants/AddCustomer.apis";
import { isRequestSuccessful } from "utils/Utils";

export const postInstalmentsMarkedAsPaid = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    ADD_CUSTOMER_APIS.offline_instalment_type_payment,
    apiMethods.POST,
    payload
  );
  if (!isRequestSuccessful(status))
    throw new Error(`Error marking instalments as paid: ${data} ${status}`);
  return data;
};
