import React from "react";
import { is_empty } from "utils/validations";
import { is_desktop } from "utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS } from "ui/pages/Dashboard/redux/actions";
import { ReactComponent as Vector } from "assets/vectors/vector.svg";
import module_style from "../../../Style.module.css";
import ExlyTooltip from "ui/modules/Tooltip";
import { Tooltip } from "@my-scoot/component-library-legacy";
import { ReactComponent as PeopleIcon } from "assets/vectors/group.svg";

const Stats = ({ listing_uid, setFilters }) => {
  const dispatch = useDispatch();

  const stats = useSelector((state) => state.dashboard.subscription_stats);

  React.useEffect(() => {
    dispatch({
      type: EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS,
      listing_uid: listing_uid,
      show_overall: true,
    });
  }, []);

  if (is_empty(stats)) return <></>;

  return is_desktop ? (
    <div className={`${module_style.stats_card} mt-2`}>
      {/* <div className={`row no-gutters align-items-center`}>
                <div>Showing stats for: </div>
                <Dropdown
                    value={filter_month}
                    handleChange={(value) => {
                        dispatch({ type: EXLY_DASHBOARD__SET_SELECTED_MONTH, payload: value });
                        dispatch({ type: EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS, listing_uid: listing_uid })
                    }}
                    width="168px"
                    options={[
                        { id: '1', label: 'Current Month', value: constants.current_month },
                        { id: '2', label: 'Last Month', value: constants.previous_month }
                    ]}
                />
            </div> */}
      <div className={`${module_style.stats_card_desktop_wrap} mt-2`}>
        {stats.map((item, index) => (
          <div
            key={item.title}
            className={`${module_style.stats_card_desktop} pointer`}
          >
            <Tooltip title={item.tooltip} color="primary" arrow>
              <div
                onClick={() => {
                  let filter = {};
                  if (index === 0) filter.subscription_status = 1;
                  else if (index === 1) filter.subscription_status = 2;
                  else if (index === 2) filter.subscription_status = 3;
                  else return;

                  setFilters(filter);
                }}
                style={{
                  borderRadius: 5,
                  backgroundColor: item.color,
                  padding: 15,
                  alignItems: "center",
                  boxShadow: "0px 1px 1px rgba(73, 58, 177, 0.08)",
                  height: "100%",
                  display: "flex",
                }}
              >
                {index === 3 ? (
                  <Vector
                    style={{
                      fill: item.title_color ? item.title_color : item.color,
                    }}
                    className={`${module_style.group_svg}`}
                  />
                ) : (
                  <PeopleIcon
                    style={{
                      fill: item.title_color ? item.title_color : item.color,
                    }}
                    className={`${module_style.group_svg}`}
                  />
                )}
                <div className={`ml-2`} style={{ flexGrow: 1 }}>
                  <div
                    style={{
                      color: item.title_color ? item.title_color : item.color,
                      fontWeight: 600,
                      fontSize: "16px",
                      whiteSpace: "break-all",
                    }}
                    className={`${module_style.card_title} mb-2`}
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      color: item.title_color ? item.title_color : item.color,
                      fontWeight: 700,
                      whiteSpace: "break-all",
                    }}
                  >
                    {item.value}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={`${module_style.stats_card}`}>
      <div className={`mt-2 ${module_style.stats_wrapper}`}>
        {stats.map((item, index) => (
          <div
            key={item.title}
            onClick={() => {
              let filter = {};
              if (index === 0) filter.subscription_status = 1;
              else if (index === 1) filter.subscription_status = 2;
              else if (index === 2) filter.subscription_status = 3;
              else return;

              setFilters(filter);
            }}
            style={{
              borderRadius: 5,
              backgroundColor: item.color,
              padding: 12,
              alignItems: "center",
              boxShadow: "0px 1px 1px rgba(73, 58, 177, 0.08)",
            }}
            className={`row no-gutters ${module_style.statsCard}`}
          >
            <div
              className="d-flex justify-content-between mb-3"
              style={{
                width: "100%",
                color: item.title_color ? item.title_color : item.color,
              }}
            >
              {index === 3 ? (
                <Vector
                  style={{
                    fill: item.title_color ? item.title_color : item.color,
                  }}
                  className={`${module_style.group_svg}`}
                />
              ) : (
                <PeopleIcon
                  style={{
                    fill: item.title_color ? item.title_color : item.color,
                  }}
                  className={`${module_style.group_svg}`}
                />
              )}
              <ExlyTooltip text={item.tooltip} />
            </div>

            <div
              style={{
                color: item.title_color ? item.title_color : item.color,
                fontWeight: 700,
                fontSize: "18px",
              }}
            >
              {item.value}
            </div>

            <div
              style={{
                color: item.title_color ? item.title_color : item.color,
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
