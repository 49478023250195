import CircularProgress from "@material-ui/core/CircularProgress";
import ExlyModal from "common/Components/ExlyModal";
import arrayMutators from "final-form-arrays";
import React from "react";
import { Form } from "react-final-form";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { is_empty } from "utils/validations";
import useCustomerInstalmentPaymentHistory from "../hooks/useCustomerInstalmentPaymentHistory";
import { postInstalmentsMarkedAsPaid } from "./AddInstalmentPaymentModal.apiCalls";
import { ADD_INSTALMENTS_PAYMENT_FORM_KEYS } from "./AddInstalmentPaymentModal.constants";
import styles from "./AddInstalmentPaymentModal.module.css";
import {
  getAddCustomerInstalmentApiPayload,
  getInstalmentsToMarkedAsPaid,
} from "./AddInstalmentPaymentModal.utils";
import InstalmentFields from "./modules/InstalmentFields/InstalmentFields";

const AddInstalmentPaymentModal = ({
  open,
  onClose,
  selectedParticipant = {},
  listingUuid,
  refetch,
}) => {
  const { notify } = useNotifications();
  const { transaction_uuid: transactionUuid } = selectedParticipant;

  const { isLoading, instalmentsPaymentHistory } =
    useCustomerInstalmentPaymentHistory({
      transactionUuid,
      listingUuid,
    });

  const handleMarkPaidUpInstalments = async (formValues) => {
    const { [ADD_INSTALMENTS_PAYMENT_FORM_KEYS.instalments]: installments } =
      formValues;

    const instalmentsToMarkPaid = getInstalmentsToMarkedAsPaid(installments);

    if (is_empty(instalmentsToMarkPaid)) {
      notify("Please mark a instalment", notification_color_keys.error);
      return;
    }

    try {
      await postInstalmentsMarkedAsPaid(
        getAddCustomerInstalmentApiPayload({
          instalmentsPaymentHistory,
          instalmentsToMarkPaid,
          listingUuid,
          selectedParticipant,
        })
      );
      notify(
        "Instalments successfully marked as paid",
        notification_color_keys.success
      );
      refetch();
    } catch (error) {
      notify(
        "Something went wrong while marking instalments, please try again",
        notification_color_keys.error
      );
      logError({
        error,
        occuredAt:
          "src/ui/pages/customers/ManageBookings/modules/AddInstalmentPaymentModal/AddInstalmentPaymentModal.jsx",
        when: "calling postInstalmentsMarkedAsPaid",
      });
    } finally {
      onClose();
    }
  };

  return (
    <Form
      onSubmit={handleMarkPaidUpInstalments}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <ExlyModal
            title="Mark Paid Up Instalments"
            className={styles.modalRoot}
            open={open}
            onClose={onClose}
            primaryBtnText="Save Changes"
            showSecondaryBtn={false}
            primaryBtnProps={{
              fullWidth: true,
              loading: submitting,
              disabled: submitting,
            }}
            onPrimaryBtnClick={handleSubmit}
          >
            {isLoading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <InstalmentFields
                instalmentsPaymentHistory={instalmentsPaymentHistory}
              />
            )}
          </ExlyModal>
        </form>
      )}
    </Form>
  );
};

export default AddInstalmentPaymentModal;
