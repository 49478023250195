import React from "react";
import { Modal, Avatar, MobileModal } from "@my-scoot/component-library-legacy";
import useStyles from "./DesktopInfoModal.styles";
import CloseIcon from "@material-ui/icons/Close";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  BookingQuestions,
  LinkField,
} from "webpage-leads/components/CustomColumns";
import {
  ScheduleSession,
  ViewSlotsField,
  WhatsAppFooterButton,
} from "../TableFields/TableFields";
import { DateTimeField } from "common/Components/TableFields/TableFields";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { promotional_upsells, upsellTypeMap } from "../../../constants";
import { is_empty } from "utils/validations";
import SessionStorageConstants from "constants/SessionStorage.constants";
import CustomColumnDetailsFieldValueMapper from "common/Components/CustomColumnDetailsFieldValueMapper/CustomColumnDetailsFieldValueMapper";
import { booking_record_slug } from "constants/schedule";
import CustomerStatus from "features/Crm/modules/Bookings/modules/CustomerStatus/CustomerStatus";

export const DesktopInfoModal = ({
  data,
  hideModal,
  isOpen,
  listing_data,
  showSlots,
  isAvailabilityNotDecided,
  customFieldToRenderInDetails,
}) => {
  const classes = useStyles();
  const [show_answer_modal, set_show_answer_modal] = React.useState(false);
  const [answer_modal_data, set_answer_modal_data] = React.useState(false);
  const isDesktop = useDesktopMediaQuery();
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );
  const isPlanManageBooking = !is_empty(
    sessionStorage.getItem(SessionStorageConstants.ENTITY_DATA)
  );

  const header = data && (
    <div className={classes.header}>
      <div className={classes.name_wrapper}>
        <Avatar label={data?.customer_name} />
        <span className="word_break">{data?.customer_name ?? "N/A"}</span>
      </div>
      <div className={classes.header_icons}>
        <div
          onClick={hideModal}
          className={`${classes.cross_icon_wrapper} pt-3 pb-3`}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  const content = (
    <div className={classes.container}>
      {!isCustomerDetailsHidden && (
        <div className={classes.row}>
          <div className={classes.row_title}>Customer Email :</div>
          <div className={classes.row_value}>
            {data?.customer_email ?? "N/A"}
          </div>
        </div>
      )}
      {!isPlanManageBooking && (
        <div className={classes.row}>
          <div className={classes.row_title}>Plan Name :</div>
          <div className={classes.row_value}>
            {data?.purchased_plan_name ?? "-"}
          </div>
        </div>
      )}
      {!isCustomerDetailsHidden && (
        <div className={classes.row}>
          <div className={classes.row_title}>Phone :</div>
          <div className={classes.row_value}>
            {data?.customer_country_code ?? ""} {data?.customer_phone ?? "N/A"}
          </div>
        </div>
      )}

      <div className={classes.row}>
        <div className={classes.row_title}>Booked on :</div>
        <div className={classes.row_value}>
          <DateTimeField record={data} source="booked_on" />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.row_title}>
          {promotional_upsells.upsell_type} :
        </div>
        <div className={classes.row_value}>
          {upsellTypeMap[data?.upsell_type] ?? "N/A"}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.row_title}>
          {promotional_upsells.source_upsell_offering_name} :
        </div>
        <div className={classes.row_value}>
          {data?.source_upsell_offering_title ?? "N/A"}
        </div>
      </div>

      {data?.slots_booked ? (
        <div className={classes.row}>
          <div className={classes.row_title}>Timezone :</div>
          <div className={classes.row_value}>
            <ViewSlotsField
              showSlots={showSlots}
              record={data}
              source="slots_booked"
              hideModal={hideModal}
            />
          </div>
        </div>
      ) : null}
      <div className={classes.row}>
        <div className={classes.row_title}>Check Answers:</div>
        <div className={classes.row_value}>
          {data?.answer_count ? (
            <LinkField
              onClick={(record) => {
                set_show_answer_modal(true);
                set_answer_modal_data(record);
              }}
              record={data ?? {}}
              source="answer_list"
              linkText="View Answers"
              className={classes.action}
            />
          ) : (
            "N/A"
          )}
        </div>
      </div>

      {isAvailabilityNotDecided && (
        <>
          <div className={classes.row}>
            <div className={classes.row_title}>Customer Status :</div>
            <div className={classes.row_value}>
              <CustomerStatus record={data} />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.row_title}>Schedule Session:</div>
            <div className={classes.row_value}>
              <ScheduleSession record={data} listing_data={listing_data} />
            </div>
          </div>
        </>
      )}
      <CustomColumnDetailsFieldValueMapper
        columns={customFieldToRenderInDetails}
        recordSlug={booking_record_slug}
        data={data}
      />
    </div>
  );

  const modalContent = (
    <>
      {show_answer_modal ? (
        <BookingQuestions
          isOpen={show_answer_modal}
          data={answer_modal_data}
          hideModal={() => {
            set_show_answer_modal(false);
          }}
          title="Booking Q&A"
        />
      ) : (
        content
      )}
    </>
  );
  const commonProps = {
    open: isOpen,
    customHeader: header,
    onClose: hideModal,
  };

  return isDesktop ? (
    <Modal {...commonProps} customFooter={<></>}>
      {modalContent}
    </Modal>
  ) : (
    <MobileModal
      {...commonProps}
      customFooter={
        isCustomerDetailsHidden ? (
          <></>
        ) : (
          <WhatsAppFooterButton
            record={data}
            source="customer_phone"
            countryCodeKey="customer_country_code"
          />
        )
      }
    >
      {modalContent}
    </MobileModal>
  );
};

export default DesktopInfoModal;
