export const ADD_INSTALMENTS_PAYMENT_FORM_KEYS = {
  instalments: "instalments",
};

export const INSTALMENT_HISTORY_RECORD_KEYS = {
  is_paid: "is_paid",
  price: "price",
  paid_on: "paid_on",
  currency: "currency",
  installment_status: "installment_status",
  installment_uuid: "installment_uuid",
  priority: "priority",
};
