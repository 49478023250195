export default {
  current_month: "current_month",
  previous_month: "previous_month",
  subscription_status_breakdown: {
    monthly: {
      Active:
        "This monthly subscription is active; more than three days are left before its next renewal.",
      Due: "This monthly subscription is about to expire in less than three days.",
      Overdue:
        "This monthly subscription has passed its renewal date and will expire after 28 days of renewal date, unless renewed.",
      Inactive:
        "This monthly subscription has expired due to no renewal, hence marked as inactive.",
      Expired:
        "A subscription is considered expired if its duration is complete.",
    },
    other: {
      Active:
        "This weekly/day-wise subscription is active; it will become inactive if not renewed for its next renewal.",
      Due: "This subscription is about to expire in less than three days.",
      Overdue:
        "This subscription has passed its renewal date and will expire after 28 days of renewal date, unless renewed.",
      Inactive:
        "This weekly/day-wise subscription has expired due to no renewal, hence marked as inactive.",
      Expired:
        "A subscription is considered expired if its duration is complete.",
    },
  },
  ScheduleEndChoices: {
    WEEK: 1,
    MONTHS: 2,
    SESSIONS: 3,
  },
  FORM_DATA: {
    status: "status",
    subscription_end_date: "subscription_end_date",
  },
  CANCELLED: 3,
};
