import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import React from "react";
import { EmailField } from "react-admin";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";

export const table_col_keys = {
  name: "customer_name",
  email: "customer_email",
  phone_number: "phone_number",
  offerings_purchased: "offerings_purchased",
};

export const getMultiplePurchasesColumnConfig = () => [
  {
    field: table_col_keys.name,
    headerName: "Name",
    valueFormatter: (record) => (
      <NameField record={record} source={table_col_keys.name} />
    ),
    avatar: true,
    sortable: false,
    width: "200px",
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 25,
    fixed: "left",
    isPrimary: true,
    isDrawerTitle: true,
    emptyField: "N/A",
  },
  {
    field: table_col_keys.email,
    headerName: "Email",
    valueFormatter: (record) => (
      <EmailField record={record} source={table_col_keys.email} />
    ),
  },
  {
    field: table_col_keys.phone_number,
    headerName: "Phone Number",
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source={table_col_keys.phone_number}
      />
    ),
  },
  {
    field: table_col_keys.offerings_purchased,
    headerName: "# Offerings Purchased",
    valueFormatter: (record) => (
      <div className="d-flex justify-content-start">
        {record?.offerings_purchased}
      </div>
    ),
  },
];

const pathName = app_route_ids[app_route_keys.multiple_purchases_details];

export const MULTIPLE_PURCHASES_FOR_LISTING_RA_PROPS = {
  basePath: pathName,
  history: {
    location: {
      pathname: pathName,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: pathName,
    search: "",
    hash: "",
  },
  match: {
    path: pathName,
    url: pathName,
    isExact: true,
    params: {},
  },

  resource: pathName,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const multiplePurchasesFilterConfig = [
  {
    source: "guest_name",
    placeholder: "Name",
    type: "input",
    disableContains: true,
  },
];
