import React, { useEffect } from "react";
import { useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { is_empty } from "utils/validations";
import {
  ADD_INSTALMENTS_PAYMENT_FORM_KEYS,
  INSTALMENT_HISTORY_RECORD_KEYS,
} from "../../AddInstalmentPaymentModal.constants";
import InstalmentField from "../InstalmentField/InstalmentField";
import { getFormInstalmentDataFromInstalmentHistory } from "./InstalmentFields.utils";
import styles from "./InstalmentFields.module.css";

const InstalmentFields = ({ instalmentsPaymentHistory }) => {
  const form = useForm();

  const [initialPaymentInstalment] = instalmentsPaymentHistory ?? [];
  const { [INSTALMENT_HISTORY_RECORD_KEYS.currency]: currency } =
    initialPaymentInstalment ?? {};

  const handleInitFormInstalmentsData = () => {
    if (is_empty(instalmentsPaymentHistory)) return;

    const [, ...instalmentsHistory] = instalmentsPaymentHistory; // not considering first instalment in history array as first instalment is considered as initial payment not an instalment

    const formInstalmentsData = instalmentsHistory.map(
      getFormInstalmentDataFromInstalmentHistory
    );

    form.change(
      ADD_INSTALMENTS_PAYMENT_FORM_KEYS.instalments,
      formInstalmentsData
    );
  };

  useEffect(() => {
    handleInitFormInstalmentsData();
  }, [instalmentsPaymentHistory]);

  return (
    <div className={styles.root}>
      <FieldArray name={ADD_INSTALMENTS_PAYMENT_FORM_KEYS.instalments}>
        {({ fields }) =>
          fields.map((name, index) => (
            <InstalmentField
              key={name}
              name={name}
              index={index}
              instalmentsData={fields.value}
              currency={currency}
            />
          ))
        }
      </FieldArray>
    </div>
  );
};

export default InstalmentFields;
