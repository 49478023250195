import React from "react";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import IconButton from "@material-ui/core/IconButton";
import module_style from "../../../Style.module.css";
import { BookingTitle } from "../commonComponents";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useHistory } from "react-router";
import classnames from "classnames";

const CustomerNavigation = ({
  listing_data,
  entity_data,
  canAddCustomers,
  canBroadcastMessage,
  set_show_add_modal,
  showBroadcastModal,
  learnMoreHref,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();
  if (!isDesktop) return null;

  return (
    <div
      className={classnames(
        module_style.nav_wrap_customer_list,
        module_style.headerContainer
      )}
    >
      <IconButton
        className={module_style.nav_back_btn}
        onClick={() => history.goBack()}
      >
        <ArrowBackRoundedIcon />
      </IconButton>
      <BookingTitle
        listing_data={listing_data}
        entity_data={entity_data}
        canAddCustomers={canAddCustomers}
        canBroadcastMessage={canBroadcastMessage}
        set_show_add_modal={set_show_add_modal}
        showBroadcastModal={showBroadcastModal}
        learnMoreHref={learnMoreHref}
        isSmall={false}
      />
    </div>
  );
};

export default CustomerNavigation;
