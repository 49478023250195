import React, { useEffect, useState } from "react";
import ExlyTable from "common/Components/ExlyTable";
import jsonExport from "jsonexport/dist";
import { downloadCSV, useRefresh } from "react-admin";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import module_style from "../../Style.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Button } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import classnames from "classnames";

import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import { useHistory } from "react-router";
import { isDefined, is_empty } from "utils/validations";
import { getAnswerText, handleDateFormat } from "utils/Utils";
import {
  COMMUNITY_TABS,
  COMMUNITY_TABS_CONFIG,
  MANAGE_BOOKINGS_LEARN_MORE_HREFS,
  PARTICIPANT_DATA_KEYS,
  promotional_upsells,
  upsellTypeMap,
} from "../constants";
import { schedule_types_ids } from "constants/schedule";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import AddInstalmentPaymentModal from "../modules/AddInstalmentPaymentModal/AddInstalmentPaymentModal";
import { TableStatusField } from "ui/pages/schedule/BookedSession/components/TableFields/TableFields";
import {
  MoreActionsButton,
  PrimaryActionField,
} from "ui/pages/limitedOffer/components/CustomColumns";
import { shouldRenderActionColumnCell } from "./ListingCustomersTable.utils";
import Tooltip from "ui/modules/Tooltip";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { BULK_ACTION_TABLES } from "features/BulkActions/BulkActions.constants";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";
import ExlyImage from "common/Components/ExlyImage";
import { BOOKING_LABEL } from "features/Crm/modules/Bookings/Bookings.constant";
import { masterFormData } from "./MasterTableConfig";
import { getUtmInfoString } from "features/Listings/utils/Listings.utils";
import { ORDER_PAYMENT_TYPE_LABELS } from "schedule-v2/constants";
import { shipping_status_options } from "features/Crm/modules/Bookings/modules/MerchandiseShippingStatusDropdown/MerchandiseShippingStatus.constants";
import { listing_answer_count } from "features/Crm/Crm.constants";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";
import {
  TELEGRAM_GROUP_STATE_ACTION_LABELS,
  TELEGRAM_GROUP_STATES,
} from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/modules/TelegramGroupState/TelegramGroupState.constants";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import {
  ShareLinkPopup,
  UserDetailsPopup,
} from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/TelegramBookings.lazy";
import { showTelegramActions } from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/utils/TelegramBookings.utils";
import cssStyles from "features/Crm/modules/Bookings/BookingsStyles.module.css";

// Empty state props

const ListingCustomersTable = (props) => {
  const { notify } = useNotifications();
  const refetch = useRefresh();

  const {
    raProps,
    listing_data,
    canBroadcastMessage,
    canAddCustomers,
    isMerchandise,
    variantsData,
    set_show_add_modal,
    isCustomerDetailsHidden,
    selectedIds,
    showActions,
    showInfoModal,
    setModalData,
    columnConfig,
    isAppointment,
    isQPP,
    isTelegramCommunity,
    isRecordedContent,
    filterConfig,
    tableChipsFormatter,
    customChipsToFormat,
    orgMembers,
    isWebinar,
    getCustomColumnsToExport,
    customColumnsHeaders,
    showAddCustomerAction,
    total,
    onSelect,
    isListingScheduleType,
    isInstallmentPaymentType,
    entity_data,
  } = props;

  const isPlan = !is_empty(entity_data);

  useEffect(() => {
    return () => onSelect([]); //@Dev need to clear the selectedIds when moved to another route
  }, []);

  const {
    getTableActions,
    showBroadcastModal,
    setShowBroadcastModal,
    handleRowsSelected,
    selectedRecords,
    initiateBroadcast,
    currentPageFullSelection,
    selectAll,
    getBroadcastActions,
    handleSelectAll,
    generateFooterProps,
  } = useBulkActions(props);

  const broadcastActions = getBroadcastActions({
    action: ({ tableName, broadcastType }) =>
      initiateBroadcast({
        tableName,
        broadcastType,
        listingUUID: listing_data?.uuid,
      }),
    tableName: BULK_ACTION_TABLES.NON_SUBSCRIPTION_BOOKINGS,
  });

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS
  );

  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();

  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [currentSelectedRecord, setCurrentSelectedRecord] = useState({});
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [actionTransactionUuid, setActionTransactionUuid] = useState(null);

  const [selectedRecord, setSelectedRecord] = useState();
  const [
    isAddInstalmentPaymentModalOpen,
    openAddInstalmentPaymentModal,
    closeAddInstalmentPaymentModal,
  ] = useToggleState(false);

  const getPrimaryActions = (record) => {
    const primaryActions = [];
    if ((isQPP || isRecordedContent) && isInstallmentPaymentType) {
      primaryActions.push({
        label: (
          <div>
            Add instalment(s) payment{" "}
            <Tooltip
              tooltipProps={{
                PopperProps: {
                  style: { marginTop: 15 }, // intentional inline styles as className prop replaces the default styles from popper.js
                },
              }}
              text="Easily mark instalment as paid, for instalment payments you have received from outside of Exly"
            />
          </div>
        ),
        onClick: handleOpenAddInstalmentPaymentModal,
      });
    }

    if (showTelegramActions(isTelegramCommunity, record)) {
      primaryActions.push({
        label:
          TELEGRAM_GROUP_STATE_ACTION_LABELS[record.tg_link_status] ||
          TELEGRAM_GROUP_STATE_ACTION_LABELS.DEFAULT,
        onClick: () => {
          setActionTransactionUuid(record.transaction_uuid);
          // @dev - If user has already joined the telegram group then show the joined user details else show joining link
          if (record.tg_link_status === TELEGRAM_GROUP_STATES.JOINED) {
            setUserDetailsOpen(true);
          } else {
            setShareLinkOpen(true);
          }
        },
      });
    }

    if (!primaryActions?.length) return undefined;

    primaryActions.push({
      renderCustomAction: () => null, // need minimum 2 primary actions to render ellipsis action column in desktop table
    });

    return primaryActions;
  };

  let _firstBulkAction = {
    text: "Add Customers",
    onClick: () => set_show_add_modal(true),
    hide: !showAddCustomerAction,
    hideSecondaryBtn: !showAddCustomerAction || isMerchandise,
    icon: (
      <ExlyImage
        src={getCrmAsset("person_add.svg")}
        height={20}
        width={20}
        id="tag"
      />
    ),
  };
  const footerProps = generateFooterProps({
    bulkActions,
    primaryFooterProps: _firstBulkAction,
  });

  const bookingsTitle = (
    <div className={module_style.topDivWrapper}>
      <span className={module_style.page_title}>
        <span onClick={() => window.history.go(-1)} className="pointer">
          Bookings
        </span>
        {listing_data?.title && (
          <span>
            {` / `}
            <span className={module_style.action_text}>
              {listing_data.title}
            </span>
          </span>
        )}
      </span>
      {isDesktop && (
        <div className={module_style.action_wrapper}>
          {canAddCustomers && !isMerchandise && (
            <Button onClick={() => set_show_add_modal(true)}>
              Add customer
            </Button>
          )}
        </div>
      )}
    </div>
  );

  useAppLayoutControl({
    values: {
      bookingsTitle,
      showBottomNav: false,
      customBackAction: () => history.goBack(),
    },
    control: {
      appBar: !isDesktop,
      bottomNav: !isDesktop,
      title: !isDesktop,
    },
  });

  //@Dev(muskan) the exporter functionality will be moved to BE soon
  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let return_data = {
        [masterFormData.name]: data.customer_name,
        [masterFormData.email]: data.customer_email,
        [masterFormData.phone]: is_empty(data.customer_phone)
          ? "-"
          : `${data.customer_country_code} ${data.customer_phone}`,
        ...(!isPlan && listing_data?.has_dropdown_plans
          ? { [masterFormData.plan_name]: data.purchased_plan_name || "-" }
          : {}),
        ...(isMerchandise && !isPlan && variantsData
          ? {
              [masterFormData.merchandise_variants.placeholder]: data.variants,
            }
          : {}),
        ...(isMerchandise && !isPlan
          ? {
              [masterFormData.quantity.placeholder]: data.quantity ?? "-",
              [masterFormData.shipping_status]:
                shipping_status_options?.find(
                  (item) => item.value === parseInt(data.dispatch_status)
                )?.label ?? "-",
              [masterFormData.delivery_address.placeholder]:
                data[masterFormData.delivery_address.field] ?? "-",
            }
          : {}),
        ...(isAppointment && !isPlan
          ? { [masterFormData.slots_booked]: data.slots_booked ?? "-" }
          : {}),
        ...(!isPlan
          ? {
              [masterFormData.price.placeholder]: isDefined(
                data[masterFormData.price.field]
              )
                ? `${constants.CURRENCY_SYMBOL_MAP[data.currency]} ${
                    data[masterFormData.price.field]
                  }`
                : "-",
            }
          : {}),
        ...(isWebinar && !isPlan
          ? {
              [masterFormData.session_booked]: `${
                data.slot_start_datetime
                  ? handleDateFormat(data.slot_start_datetime)
                  : "-"
              }`,
            }
          : {}),
        [masterFormData.booked_on]: `${
          data.booked_on ? handleDateFormat(data.booked_on) : "-"
        }`,
        ...(isInstallmentPaymentType && !isPlan
          ? {
              [masterFormData.payment_type.placeholder]: data?.payment_type
                ? ORDER_PAYMENT_TYPE_LABELS[data?.payment_type]
                : "-",
              [masterFormData.installments.placeholder]: `${
                data[masterFormData.installments.field] || 0
              } of ${data.total_installments || 0} installments`,
            }
          : {}),
        [promotional_upsells.upsell_type]:
          upsellTypeMap[data.upsell_type] || "-",
        [promotional_upsells.source_upsell_offering_name]:
          data.source_upsell_offering_name || "-",
        ...(!isPlan
          ? {
              [masterFormData.utm_info.placeholder]: getUtmInfoString({
                record: data,
              }),
            }
          : {}),
        ...(isListingScheduleType || isPlan
          ? { Timezone: data.customer_timezone }
          : {}),
        [listing_answer_count]: `${data?.answer_count || "-"}`,
        ...getCustomColumnsToExport(data),
      };
      if (data?.answer_count > 0 && !is_empty(data.answer_list)) {
        for (let item of data.answer_list) {
          if (item.question_text in return_data) continue;
          return_data[item.question_text] = getAnswerText(item);
        }
      }
      return return_data;
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          masterFormData.name,
          masterFormData.email,
          masterFormData.phone,
          ...(!isPlan && listing_data?.has_dropdown_plans
            ? [masterFormData.plan_name]
            : []),
          ...(isMerchandise && !isPlan && variantsData
            ? [masterFormData.merchandise_variants.placeholder]
            : []),
          ...(isMerchandise && !isPlan
            ? [
                masterFormData.quantity.placeholder,
                masterFormData.shipping_status,
                masterFormData.delivery_address.placeholder,
              ]
            : []),
          ...(isAppointment && !isPlan ? [masterFormData.slots_booked] : []),
          ...(!isPlan ? [masterFormData.price.placeholder] : []),
          ...(isWebinar && !isPlan ? [masterFormData.session_booked] : []),
          masterFormData.booked_on,
          ...(isInstallmentPaymentType && !isPlan
            ? [
                masterFormData.payment_type.placeholder,
                masterFormData.installments.placeholder,
              ]
            : []),
          promotional_upsells.upsell_type,
          promotional_upsells.source_upsell_offering_name,
          ...(!isPlan ? [masterFormData.utm_info.placeholder] : []),
          ...(isListingScheduleType ? [masterFormData.timezone] : []),
          listing_answer_count,
          ...customColumnsHeaders,
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Listing Customers"); // download as 'posts.csv` file
      }
    );
  };

  const isCommunity =
    listing_data?.type === schedule_types_ids.branded_community;

  const handleOpenAddInstalmentPaymentModal = (
    record,
    rows,
    setDrawerConfig,
    setActionsDrawerConfig
  ) => {
    const {
      [PARTICIPANT_DATA_KEYS.all_installments_paid]: isAllInstalmentsPaid,
    } = record;

    if (isAllInstalmentsPaid) {
      notify(
        "All instalments are already paid up!",
        notification_color_keys.error
      );
      return;
    }

    setSelectedRecord(record);
    openAddInstalmentPaymentModal();
    if (setDrawerConfig) setActionsDrawerConfig({ row: null, open: false });
  };

  const handleOpenInfoModal = (
    row,
    rows,
    setDrawerConfig,
    setActionsDrawerConfig
  ) => {
    showInfoModal();
    setModalData(row);
    if (setDrawerConfig) setActionsDrawerConfig({ row: null, open: false });
  };

  let customPrimaryAction = {
    label: "More Actions",
    renderCustomAction: (
      row,
      rows,
      setDrawerConfig,
      setActionsDrawerConfig
    ) => {
      return shouldRenderActionColumnCell(row, isTelegramCommunity) ? (
        <MoreActionsButton
          label="More Actions"
          onClick={() => setActionsDrawerConfig({ row, open: true })}
        />
      ) : (
        <MoreActionsButton
          label="See Details"
          onClick={() => {
            handleOpenInfoModal(row);
          }}
        />
      );
    },
    onClose: (row, rows, setDrawerConfig, setActionsDrawerConfig) =>
      setActionsDrawerConfig({ row: null, open: false }),
  };
  return (
    <>
      <ExlyTable
        ra_props={{
          ...raProps,
          exporter: orgPermissions.canExport() ? exporter : false,
        }}
        entityName={BOOKING_LABEL}
        showSelectAllcheckBox
        bulkActionComponent={
          <SelectionCard
            actions={bulkActions}
            selectedRecords={Object.keys(selectedRecords)}
            totalRecords={total}
            currentPageFullSelection={currentPageFullSelection}
            entityName={BOOKING_LABEL}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
        }
        allValuesSelected={selectAll}
        tabConfig={
          isCommunity ? COMMUNITY_TABS_CONFIG(listing_data) : undefined
        }
        selectedTab={isCommunity ? COMMUNITY_TABS.COMMUNITY.value : undefined}
        noExportButton={!orgPermissions.canExport()}
        tableTitle={!isDesktop && bookingsTitle}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "120px",
          layoutDesktopMargin: "20px 0px 0px 0px",
          layoutMobileMargin: "0",
          paddingMobile: "0px",
          noMobileBoxShadow: true,
          learnMoreHref: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS,
        }}
        checkboxSelection
        selected={selectedIds}
        onRowSelected={handleRowsSelected}
        columnConfig={columnConfig}
        tableFilters={filterConfig}
        showFixedBars
        tableChipsFormatter={(filter, value) =>
          tableChipsFormatter({
            filter,
            value,
            variantsData,
            customChipsToFormat,
            orgMembers,
            listingData: listing_data,
          })
        }
        renderPrimaryColumnRightNode={(record) =>
          !isCustomerDetailsHidden && (
            <WhatsAppButton
              record={record}
              source="customer_phone"
              countryCodeKey="customer_country_code"
            />
          )
        }
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        hasMobileFooter={showActions}
        footerProps={footerProps}
        getRecordFooterClassName={(record) =>
          classnames(
            module_style.mobile_footer,
            record.customer_schedule_status &&
              module_style[`mobile_footer_${record.customer_schedule_status}`],
            isMerchandise &&
              {
                [constants.dispatchStatus.PENDING]: module_style.pending,
                [constants.dispatchStatus.DISPATCHED]: module_style.dispatched,
                [constants.dispatchStatus.DELIVERED]: module_style.delivered,
              }[record.dispatch_status]
          )
        }
        {...(isPlan || isQPP || isRecordedContent || isTelegramCommunity
          ? { customPrimaryAction }
          : {})}
        {...(isPlan
          ? {
              renderSecondaryAction: (record) => (
                <TableStatusField record={record} />
              ),
            }
          : {})}
        primaryKey="id"
        shouldRenderPrimaryAction={(record) =>
          shouldRenderActionColumnCell(record, isTelegramCommunity)
        }
        desktopCustomPrimaryAction={{
          renderCustomAction: (row, rows, setAnchorEl, setOpenPopper) => (
            <PrimaryActionField
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setOpenPopper(true);
                setCurrentSelectedRecord(row);
              }}
            />
          ),
        }}
        primaryActions={getPrimaryActions(currentSelectedRecord)}
        primaryColumnProps={{
          align: "center",
          sticky: true,
          title: "Action",
          className: cssStyles.actionColumnStyle,
        }}
        emptyStateProps={{
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "No Offering Customers yet.",
          description:
            "As soon as we have a new offering customer, it will show up here. View the video to learn more.",
          videoSrc: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS_VIDEO,
          primaryCtaProps: {
            title: "Add Customer",
            onClick: () => set_show_add_modal(true),
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS,
          },
          className: "mb-5",
        }}
      />

      {isAddInstalmentPaymentModalOpen ? (
        <AddInstalmentPaymentModal
          open={isAddInstalmentPaymentModalOpen}
          onClose={closeAddInstalmentPaymentModal}
          selectedParticipant={selectedRecord}
          listingUuid={listing_data?.uuid}
          refetch={refetch}
        />
      ) : null}

      {canBroadcastMessage && showBroadcastModal && (
        <BroadcastModal
          open={showBroadcastModal}
          onClose={() => setShowBroadcastModal(false)}
          modal_props={{ title: "Send Message" }}
          mobile_modal_props={{ header: "Select Broadcast medium" }}
          broadcastActions={broadcastActions}
        />
      )}

      {shareLinkOpen && actionTransactionUuid && (
        <LazyLoadWrapper
          loadingCondition={shareLinkOpen && actionTransactionUuid}
        >
          <ShareLinkPopup
            isOpen={shareLinkOpen}
            onClose={() => {
              setShareLinkOpen(false);
              setActionTransactionUuid(null);
            }}
            transactionUuid={actionTransactionUuid}
          />
        </LazyLoadWrapper>
      )}

      {userDetailsOpen && actionTransactionUuid && (
        <LazyLoadWrapper
          loadingCondition={userDetailsOpen && actionTransactionUuid}
        >
          <UserDetailsPopup
            isOpen={userDetailsOpen}
            onClose={() => {
              setUserDetailsOpen(false);
              setActionTransactionUuid(null);
            }}
            transactionUuid={actionTransactionUuid}
          />
        </LazyLoadWrapper>
      )}
    </>
  );
};

export default React.memo(ListingCustomersTable);
