import { dataProvider } from "data";
import { MANAGE_BOOKINGS_APIS } from "./ManageBookings.apis";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";

export const fetchInstalmentHistory = async ({
  listingUuid,
  transactionUuid,
}) => {
  const { data, status } = await dataProvider.custom_request(
    MANAGE_BOOKINGS_APIS.instalment_history,
    apiMethods.GET,
    { listing_uid: listingUuid, transaction_uuid: transactionUuid }
  );
  if (!isRequestSuccessful(status))
    throw new Error(
      `Error fetching customer instalments history: ${data} ${status}`
    );
  return data.creator_customer_installment_history;
};
