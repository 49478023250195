import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import React from "react";
import { EmailField } from "react-admin";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";

// Enum definitions
export const attendeesValues = {
  has_attended: "has_attended",
  not_attended: "not_attended",
};

const TableColumnKeys = {
  Name: "customer_name",
  email: "customer_email",
  PhoneNumber: "phone_number",
};

// Constants
export const tabFilterConfig = [
  {
    label: "Attended",
    source: attendeesValues.has_attended,
    filterValue: true,
    value: attendeesValues.has_attended,
  },
  {
    label: "Didn't Attend",
    source: attendeesValues.has_attended,
    filterValue: false,
    value: attendeesValues.not_attended,
  },
];

export const tableChipsFormatter = (filter) => {
  switch (filter) {
    case attendeesValues.has_attended:
      return null;

    default:
      return undefined;
  }
};

export const getAttendeesColumnConfig = () => [
  {
    field: TableColumnKeys.Name,
    headerName: "Name",
    valueFormatter: (record) => (
      <NameField record={record} source={TableColumnKeys.Name} />
    ),
    avatar: true,
    sortable: false,
    width: "200px",
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 25,
    fixed: "left",
    isPrimary: true,
    isDrawerTitle: true,
    emptyField: "N/A",
  },
  {
    field: TableColumnKeys.Email,
    headerName: "Email",
    valueFormatter: (record) => (
      <EmailField record={record} source={TableColumnKeys.email} />
    ),
  },
  {
    field: TableColumnKeys.PhoneNumber,
    headerName: "Phone Number",
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source={TableColumnKeys.PhoneNumber}
      />
    ),
  },
];

const pathName = app_route_ids[app_route_keys.attendees_details];

export const ATTENDEES_FOR_LISTING_RA_PROPS = {
  basePath: pathName,
  history: {
    location: {
      pathname: pathName,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: pathName,
    search: "",
    hash: "",
  },
  match: {
    path: pathName,
    url: pathName,
    isExact: true,
    params: {},
  },

  resource: pathName,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const attendeesFilterConfig = [
  {
    source: "guest_name",
    placeholder: "Name",
    type: "input",
    disableContains: true,
  },
];
