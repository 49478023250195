import React from "react";
import module_style from "../../../Style.module.css";
import { Shimmer } from "@my-scoot/component-library-legacy";
import style from "../../../../../../Style.module.css";
import constants from "constants/constants";
import {
  AppointmentDetails,
  ClassDetails,
  WebinarDetails,
  WorkshopDetails,
} from "../../SubscriptionCustomers/Components/TableFields/TableFields";
import MerchandiseVariants from "../../ListingCustomers/components/MerchandiseVariants/MerchandiseVariants";
import UpsellStatsCard from "../../ListingCustomers/components/UpsellStatsCard";
import AttendeesPopup from "../../ListingCustomers/components/AttendeesPopup";
import { useHistory } from "react-router";
import MultiplePurchasesPopup from "../../ListingCustomers/components/MultiplePurchasesPopup";
import UpsellPopup from "../../ListingCustomers/components/UpsellPopup";
import { is_empty } from "utils/validations";
import AddCustomerModal from "features/Crm/modules/AddCustomerModal/components/AddCustomerModal";

const ListingDetails = (props) => {
  const {
    isDesktop,
    isMerchandise,
    listing_data,
    entity_data,
    variantsData,
    availibility_type,
    set_show_modal,
    set_show_add_modal,
    appointments,
    loading,
    isClass,
    show_more,
    show_add_modal,
    showUpsellModal,
    setShowUpsellModal,
    showPurchasesModal,
    setShowPurchasesModal,
    showAttendeesModal,
    setShowAttendeesModal,
    upsellCardsArray,
    isWebinar,
    showOfferingSelector,
    handleFetchListingDetails,
  } = props;

  let entity_title = listing_data?.title;
  let entity_cover_image = listing_data?.cover_image;

  if (!is_empty(entity_data)) {
    entity_title = entity_data?.title;
    entity_cover_image = entity_data?.cover_image;
  }

  const history = useHistory();

  return (
    <div
      className={`component-wrapper ${module_style.listing_detail_container} ${
        !isDesktop && "mb-3"
      }`}
    >
      <div className={module_style.leftDivWrapper}>
        <img
          className={`${module_style.offering_image}`}
          src={entity_cover_image}
        />
        {loading ? (
          <div>
            <Shimmer
              width="105px"
              height="22px"
              wrapper_classname={module_style.mb_12}
              borderRadius="12px"
            />
            <Shimmer
              width="194px"
              height="12px"
              wrapper_classname={module_style.mb_12}
              borderRadius="12px"
            />
            <Shimmer width="99px" height="12px" borderRadius="12px" />
          </div>
        ) : (
          <>
            <div className={` ${module_style.listing_container}`}>
              <div id="listing_desc_container" className={``}>
                <div
                  style={{ marginBottom: 5, fontWeight: 700, fontSize: 16 }}
                  className={`${style.bold_black_medium_text}`}
                >
                  {entity_title}
                </div>
                <div
                  style={{ marginBottom: 5, fontSize: 14 }}
                  className={`${style.bold_black_normal_text}`}
                >
                  {constants.schedule_type[listing_data?.type]?.name}
                </div>
                {
                  //TODO: Simplify the below ternary operators
                  availibility_type == constants.avaibility_type.not_decided ? (
                    <div className={module_style.no_schedule_div}>
                      Schedule
                      <p>Not Sure, Set After Booking</p>
                    </div>
                  ) : listing_data?.type ===
                    constants.scheduleTypesId.workshop ? (
                    <WorkshopDetails listingData={listing_data} />
                  ) : listing_data?.type ===
                    constants.scheduleTypesId.one_on_one_appointment ? (
                    <AppointmentDetails appointments={appointments} />
                  ) : isWebinar ? (
                    <WebinarDetails listingData={listing_data} />
                  ) : isClass ? (
                    <ClassDetails listingData={listing_data} />
                  ) : null
                }
                {isMerchandise && isDesktop && (
                  <MerchandiseVariants
                    listingData={listing_data}
                    isDesktop={isDesktop}
                    variantsData={variantsData}
                  />
                )}
              </div>

              {show_more && (
                <div
                  onClick={() => set_show_modal(true)}
                  className={`action_text ${module_style.more}`}
                >
                  More...
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={module_style.cardsWrapper}>
        {upsellCardsArray?.map((cardValues) => (
          <UpsellStatsCard
            Icon={cardValues.Icon}
            key={cardValues.title}
            statsNumber={cardValues.statsNumber}
            title={cardValues.title}
            color={cardValues.color}
            onCardClick={cardValues.onClick}
            tooltipString={cardValues.tooltipString}
          />
        ))}
      </div>
      {show_add_modal && (
        <AddCustomerModal
          open={show_add_modal}
          onClose={() => {
            set_show_add_modal(false);
          }}
          listing_data={listing_data}
          entity_data={entity_data}
          availibility_type={availibility_type} //for not sure type customer
          showOfferingSelector={showOfferingSelector}
          onSuccess={handleFetchListingDetails}
        />
      )}
      {showUpsellModal && (
        <UpsellPopup
          open={showUpsellModal}
          listingUuid={listing_data?.uuid}
          onClose={() => {
            setShowUpsellModal(false);
            history.goBack();
          }}
        />
      )}
      {showPurchasesModal && (
        <MultiplePurchasesPopup
          listingUuid={listing_data?.uuid}
          open={showPurchasesModal}
          onClose={() => {
            setShowPurchasesModal(false);
            history.goBack();
          }}
        />
      )}
      {showAttendeesModal && (
        <AttendeesPopup
          listingUuid={listing_data?.uuid}
          open={showAttendeesModal}
          onClose={() => {
            setShowAttendeesModal(false);
            history.goBack();
          }}
        />
      )}
    </div>
  );
};

export default ListingDetails;
