import React from "react";
import { Card, Box, Tooltip } from "@my-scoot/component-library-legacy";
import useStyles from "./StatsCard.styles";
import PeopleIcon from "@material-ui/icons/People";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const UpsellStatsCard = ({
  color = "primary",
  title,
  statsNumber,
  Icon = PeopleIcon,
  className,
  onCardClick,
  tooltipString = "",
}) => {
  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles({ color, isDesktop });

  return (
    <Card
      className={[classes.statsCardWrapper, className].join(" ")}
      elevation={0}
      onClick={onCardClick}
    >
      <div className={classes.topDivWrapper}>
        <Icon className={classes.statsIcon} />
        <Tooltip arrow color="primary" title={tooltipString}>
          <InfoOutlinedIcon className={classes.toolTipIcon} />
        </Tooltip>
      </div>

      <span className={classes.statsNumber}>{statsNumber}</span>

      <Box className={classes.titleSubtitleWrapper}>
        <Box className={classes.titleWrapper}>
          <span className={classes.statsTitle}>{title}</span>
        </Box>
      </Box>
    </Card>
  );
};

export default React.memo(UpsellStatsCard);
