import country_codes from "constants/countryCodes";
import { ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS } from "features/Crm/modules/AddCustomerModal/modules/InstalmentsField/modules/InstalmentField/InstalmentField.constants";
import { is_empty } from "utils/validations";
import {
  ADD_CUSTOMER_API_PAYLOAD_KEYS,
  ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD,
  PARTICIPANT_DATA_KEYS,
} from "../../constants";
import { INSTALMENT_HISTORY_RECORD_KEYS } from "./AddInstalmentPaymentModal.constants";

export const getInstalmentsToMarkedAsPaid = (instalments) => {
  if (is_empty(instalments)) return [];

  const instalmentsToMarkAsPaid = [];

  instalments.forEach((instalment) => {
    const {
      [ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end]:
        isInstalmentMarkedPaidOnFrontEnd,
    } = instalment;

    if (isInstalmentMarkedPaidOnFrontEnd) {
      const updatedInstalment = { ...instalment };

      delete updatedInstalment[
        ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end
      ];
      delete updatedInstalment[INSTALMENT_HISTORY_RECORD_KEYS.is_paid];

      instalmentsToMarkAsPaid.push(updatedInstalment);
    }
  });

  return instalmentsToMarkAsPaid;
};

export const getAddCustomerInstalmentApiPayload = ({
  selectedParticipant,
  instalmentsPaymentHistory,
  listingUuid,
  instalmentsToMarkPaid,
}) => {
  const {
    [PARTICIPANT_DATA_KEYS.customer_name]: customerName,
    [PARTICIPANT_DATA_KEYS.username]: customerUsername,
    [PARTICIPANT_DATA_KEYS.customer_email]: customerEmail,
    [PARTICIPANT_DATA_KEYS.customer_phone]: customerPhone,
    [PARTICIPANT_DATA_KEYS.customer_country_code]: customerCountryCode,
    [PARTICIPANT_DATA_KEYS.customer_timezone]: customerTimezone,
    [PARTICIPANT_DATA_KEYS.transaction_uuid]: transactionUuid,
  } = selectedParticipant;
  const [initialPaymentInstalment] = instalmentsPaymentHistory;
  const { [INSTALMENT_HISTORY_RECORD_KEYS.currency]: currency } =
    initialPaymentInstalment;

  return {
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.full_name]: customerName,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.email]: customerEmail,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.phone]: customerPhone,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.country_code]: customerCountryCode,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.country]: country_codes.find(
      (item) => item.dial_code === customerCountryCode
    )?.name,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.listing_uid]: listingUuid,
    [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.transaction_uuid]:
      transactionUuid,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.quantity]: 1,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.currency]: currency,
    [ADD_CUSTOMER_API_PAYLOAD_KEYS.customer_timezone]: customerTimezone,
    [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.installments]:
      instalmentsToMarkPaid,
    [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.username]: customerUsername,
  };
};
