import React from "react";
import module_style from "../../Style.module.css";
import useListingCustomers from "../components/useListingCustomers";
import CustomerNavigation from "../components/CustomerNavigation";
import ListingDetails from "../components/ListingDetails";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { COMMUNITY_TABS, COMMUNITY_TABS_CONFIG } from "../constants";
import BookedSession from "ui/pages/schedule/BookedSession/BookedSession";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { getBroadcastActions } from "../ListingCustomers/ListCustomersTableConfig";

export const BonusOfferingCustomers = withComponentLibraryTheme((props) => {
  const {
    isDesktop,
    listing_data,
    availibility_type,
    selectedIds,
    onSelect,
    canBroadcastMessage,
    isBroadcastModalActive,
    hideBroadcastModal,
    broadcastActions,
    show_add_modal,
    set_show_add_modal,
    loading,
    show_more,
    showBroadcastModal,
    showUpsellModal,
    setShowUpsellModal,
    handleFetchListingDetails,
  } = useListingCustomers({
    ...props,
    getBroadcastActions,
  });

  const navigationProps = {
    listing_data,
    canBroadcastMessage,
    set_show_add_modal,
    showBroadcastModal,
  };

  const listingDetailsProps = {
    isDesktop,
    listing_data,
    availibility_type,
    canBroadcastMessage,
    loading,
    show_more,
    showBroadcastModal,
    isBroadcastModalActive,
    hideBroadcastModal,
    broadcastActions,
    show_add_modal,
    showUpsellModal,
    setShowUpsellModal,
    handleFetchListingDetails,
  };

  const mobileNavbar = (
    <span className={module_style.page_title}>
      <span onClick={() => window.history.go(-1)} className="pointer">
        Bookings
      </span>
      {listing_data?.title && (
        <span>
          {` / `}
          <span className={module_style.action_text}>{listing_data.title}</span>
        </span>
      )}
    </span>
  );

  return (
    <div className={module_style.listing_modal_contaner}>
      <CustomerNavigation {...navigationProps} />
      <ListingDetails {...listingDetailsProps} />

      <BookedSession
        {...props}
        tableProps={{
          tabConfig: COMMUNITY_TABS_CONFIG(listing_data),
          selectedTab: COMMUNITY_TABS.BONUS_OFFERINSG.value,
          selected: selectedIds,
          onRowSelected: onSelect,
          checkboxSelection: true,
          primary_cta: null,
          footerProps: null,
          hasMobileFooter: false,
          tableTitle: !isDesktop ? mobileNavbar : null,
          content_title: "No bookings yet",
          children:
            "All the bookings that you receive on your bonus offerings via community, will be displayed here",
        }}
        from={app_route_ids[app_route_keys.bonus_offering_customers]}
      />
    </div>
  );
});
