import React from "react";
import { useForm } from "react-final-form";
import {
  ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS,
  ADD_CUSTOMER_INSTALMENT_FIELD_KEYS,
} from "features/Crm/modules/AddCustomerModal/modules/InstalmentsField/modules/InstalmentField/InstalmentField.constants";
import InstalmentFieldSkeleton from "features/Crm/modules/AddCustomerModal/modules/InstalmentsField/modules/InstalmentField/modules/InstalmentFieldSkeleton/InstalmentFieldSkeleton";
import { finalFormFieldArrayNameGenerator } from "utils/string";
import {
  ADD_INSTALMENTS_PAYMENT_FORM_KEYS,
  INSTALMENT_HISTORY_RECORD_KEYS,
} from "../../AddInstalmentPaymentModal.constants";
import styles from "./InstalmentField.module.css";

const InstalmentField = ({
  name,
  instalmentsData,
  index: currentInstalmentIndex,
  currency,
}) => {
  const form = useForm();

  const isFirstInstalment = currentInstalmentIndex === 0;
  const previousInstalment = instalmentsData[currentInstalmentIndex - 1];
  const currentInstalmentData = instalmentsData[currentInstalmentIndex];
  const {
    [ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end]:
      isPreviousInstalmentMarkedAsPaidOnFrontEnd,
    [INSTALMENT_HISTORY_RECORD_KEYS.is_paid]: isInstalmentPaidOnBackend,
  } = previousInstalment ?? {};
  const {
    [ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end]:
      isCurrentInstalmentMarkedAsPaidOnFrontEnd,
    [INSTALMENT_HISTORY_RECORD_KEYS.is_paid]: isInstalmentMarkedPaidOnBackend,
  } = currentInstalmentData;

  const isPreviousInstalmentMarkedAsPaid =
    isPreviousInstalmentMarkedAsPaidOnFrontEnd || isInstalmentPaidOnBackend;

  // linear marking
  // basis: if previous instalment marked as paid
  const disabled =
    isInstalmentMarkedPaidOnBackend ||
    (!isFirstInstalment && !isPreviousInstalmentMarkedAsPaid);

  const markLaterInstalmentsUnpaid = () => {
    const {
      mutators: { update },
    } = form;

    // unmark all next instalments if this instalment marked as unpaid
    for (
      let index = currentInstalmentIndex + 1;
      index < instalmentsData.length;
      index += 1
    ) {
      const instalmentData = instalmentsData[index];
      update(ADD_INSTALMENTS_PAYMENT_FORM_KEYS.instalments, index, {
        ...instalmentData,
        [ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end]: false,
      });
    }
  };

  const handleMarkAsPaid = () => {
    if (isCurrentInstalmentMarkedAsPaidOnFrontEnd) markLaterInstalmentsUnpaid();

    form.change(
      finalFormFieldArrayNameGenerator([
        name,
        ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end,
      ]),
      !isCurrentInstalmentMarkedAsPaidOnFrontEnd
    );
  };

  return (
    <InstalmentFieldSkeleton
      className={styles.root}
      actionsClassName={styles.actions}
      priceFieldName={finalFormFieldArrayNameGenerator([
        name,
        ADD_CUSTOMER_INSTALMENT_FIELD_KEYS.installment_price,
      ])}
      disabled={disabled}
      currency={currency}
      instalmentNumber={currentInstalmentIndex + 1}
      isMarkedPaid={
        isInstalmentMarkedPaidOnBackend ||
        isCurrentInstalmentMarkedAsPaidOnFrontEnd
      }
      onClickMark={handleMarkAsPaid}
    />
  );
};

export default InstalmentField;
