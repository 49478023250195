import React from "react";
import { Tooltip } from "@my-scoot/component-library-legacy";
import {
  BOOKING_STATUS_ID,
  BOOKING_STATUS_MAP,
  INACTIVE_BOOKING_STATUS,
} from "constants/bookings.constants";
import { schedule_types_ids } from "constants/schedule";

export const StatusField = ({ record, showTooltip = true, offeringData }) => {
  const formatedString = React.useMemo(() => {
    switch (offeringData?.type) {
      case schedule_types_ids.rolling_class:
        if (record?.status !== BOOKING_STATUS_ID.active) {
          return INACTIVE_BOOKING_STATUS.label;
        }
        return BOOKING_STATUS_MAP[BOOKING_STATUS_ID.active].title;

      default:
        return BOOKING_STATUS_MAP[record?.status].title;
    }
  }, [offeringData]);

  const statusText = React.useMemo(() => {
    const className =
      record?.status === BOOKING_STATUS_ID.active
        ? BOOKING_STATUS_MAP[BOOKING_STATUS_ID.active].className
        : INACTIVE_BOOKING_STATUS.className;
    return <span className={`${className}`}>{formatedString}</span>;
  }, [formatedString]);

  return (
    <>
      {showTooltip ? (
        <Tooltip title={formatedString} color="primary" arrow>
          {statusText}
        </Tooltip>
      ) : (
        <> {statusText}</>
      )}
    </>
  );
};
