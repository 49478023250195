import React, { useEffect, useState } from "react";
import module_style from "../../Style.module.css";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { BookingQuestions } from "webpage-leads/components/CustomColumns";
import DesktopInfoModal from "./components/DesktopInfoModal/DesktopInfoModal";
import MerchandiseVariants from "./components/MerchandiseVariants/MerchandiseVariants";
import DispatchConfirmationModal from "ui/pages/schedule/BookedSession/components/miscellaneous/DispatchConfirmationModal";
import useListingCustomers from "../components/useListingCustomers";
import ListingDetails from "../components/ListingDetails";
import CustomerNavigation from "../components/CustomerNavigation";
import {
  getFilterConfig,
  form_data,
  getTableConfigDefault,
  getTableConfigNotDecided,
  getBroadcastActions,
} from "./ListCustomersTableConfig";
import ListingCustomersTable from "../components/ListingCustomersTable";
import ListingDetailModal from "../components/ListingDetailModal";
import { useListController } from "react-admin";
import { is_empty } from "utils/validations";
import { masterTableChipsFormatter } from "../components/MasterTableConfig";
import { BOOKING_UNIQUE_ID_KEY } from "features/Crm/modules/Bookings/Bookings.constant";
import { MANAGE_BOOKINGS_LEARN_MORE_HREFS } from "../constants";

//@DEV(muskan) CRM TABLES WILL BE REVAMPED IN R4, ALSO NEED TO REVAMP THIS PROP DRILLING.
const ListingCustomers = (props) => {
  const { data, loaded, total, onSelect } = useListController(props);

  const [scrollPosition, setScrollPosition] = useState(0);
  const {
    isDesktop,
    isMerchandise,
    listing_data,
    entity_data,
    variantsData,
    availibility_type,
    hasPagination,
    selectedIds,
    columnConfig,
    filterConfig,
    isCustomerDetailsHidden,
    showActions,
    footerProps,
    showInfoModal,
    isAppointment,
    isQPP,
    isTelegramCommunity,
    isRecordedContent,
    handleShowShippingStatusUpdateModal,
    isQuickPaymentPage,
    canBroadcastMessage,
    isBroadcastModalActive,
    hideBroadcastModal,
    broadcastActions,
    slots_data,
    set_slots_data,
    show_add_modal,
    show_modal,
    set_show_modal,
    set_show_add_modal,
    isInfoModalActive,
    modalData,
    setModalData,
    hideInfoModal,
    showSlots,
    show_answer_modal,
    set_show_answer_modal,
    answer_modal_data,
    isDispatchModalActive,
    hideDispatchModal,
    processing,
    handleUpdateShippingStatus,
    appointments,
    loading,
    isClass,
    show_more,
    canAddCustomers,
    showBroadcastModal,
    showUpsellModal,
    setShowUpsellModal,
    showPurchasesModal,
    setShowPurchasesModal,
    showAttendeesModal,
    setShowAttendeesModal,
    upsellCardsArray,
    isWebinar,
    customChipsToFormat,
    orgMembers,
    getCustomColumnsToExport,
    customColumnsHeaders,
    customFieldToRenderInDetails,
    handleFetchListingDetails,
    showAddCustomerAction,
    isListingScheduleType,
    isOfferingAutoDebitEnabled,
    isInstallmentPaymentType,
  } = useListingCustomers({
    ...props,
    getFilterConfig,
    form_data,
    getTableConfigDefault,
    getTableConfigNotDecided,
    getBroadcastActions,
    setScrollPosition,
  });

  const listingDetailsProps = {
    isDesktop,
    isMerchandise,
    listing_data,
    entity_data,
    variantsData,
    availibility_type,
    canBroadcastMessage,
    set_show_modal,
    set_show_add_modal,
    appointments,
    loading,
    isClass,
    show_more,
    canAddCustomers,
    showBroadcastModal,
    isBroadcastModalActive,
    hideBroadcastModal,
    broadcastActions,
    show_add_modal,
    showUpsellModal,
    setShowUpsellModal,
    showPurchasesModal,
    setShowPurchasesModal,
    showAttendeesModal,
    setShowAttendeesModal,
    upsellCardsArray,
    isWebinar,
    showOfferingSelector: false,
    handleFetchListingDetails,
  };

  const navigationProps = {
    listing_data,
    entity_data,
    canAddCustomers,
    canBroadcastMessage,
    set_show_add_modal,
    showBroadcastModal,
    learnMoreHref: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS,
  };

  const listingCustomerTableProps = {
    raProps: props,
    listing_data,
    canBroadcastMessage,
    canAddCustomers,
    isMerchandise,
    isQPP,
    isTelegramCommunity,
    isRecordedContent,
    showBroadcastModal,
    hasPagination,
    variantsData,
    set_show_add_modal,
    isCustomerDetailsHidden,
    selectedIds,
    showActions,
    showInfoModal,
    footerProps,
    setModalData,
    columnConfig,
    isAppointment,
    filterConfig,
    handleShowShippingStatusUpdateModal,
    isQuickPaymentPage,
    tableChipsFormatter: masterTableChipsFormatter,
    customChipsToFormat,
    orgMembers,
    getCustomColumnsToExport,
    customColumnsHeaders,
    isWebinar,
    showAddCustomerAction,
    total,
    onSelect,
    isListingScheduleType,
    isOfferingAutoDebitEnabled,
    isInstallmentPaymentType,
    entity_data,
    ...props,
  };

  const listingsCustomersType = listing_data?.type;
  const listingDetailModalProps = {
    listing_data,
    form_data,
    slots_data,
    show_modal,
    set_show_modal,
    set_slots_data,
    listingsCustomersType,
    appointments,
    isMerchandise,
    variantsData,
    isWebinar,
  };

  useEffect(() => {
    /** if : scrollPosition
     * then : run window.scrollTo
     * why: when any record is updated from table ,  we need to scroll back to that record after refresh.
     */
    if (loaded && !is_empty(data) && scrollPosition)
      window.scrollTo(0, scrollPosition);
  }, [loaded, data]);
  return (
    <div className={module_style.listing_modal_contaner}>
      <CustomerNavigation {...navigationProps} />
      <ListingDetails {...listingDetailsProps} />

      <div className={module_style.customer_wrapper}>
        {isMerchandise && !isDesktop && (
          <MerchandiseVariants
            listingData={listing_data}
            isDesktop={isDesktop}
            variantsData={variantsData}
          />
        )}
        <ListingCustomersTable {...listingCustomerTableProps} />

        <ListingDetailModal {...listingDetailModalProps} />

        {isInfoModalActive && (
          <DesktopInfoModal
            data={modalData}
            setModalData={setModalData}
            isOpen={isInfoModalActive}
            hideModal={() => {
              hideInfoModal();
            }}
            listing_data={listing_data}
            showSlots={showSlots}
            customFieldToRenderInDetails={customFieldToRenderInDetails}
          />
        )}

        {show_answer_modal && (
          <BookingQuestions
            isOpen={show_answer_modal}
            data={answer_modal_data}
            hideModal={() => {
              set_show_answer_modal(false);
            }}
            title="Booking Q&A"
          />
        )}

        <DispatchConfirmationModal
          title="Confirm"
          open={isDispatchModalActive}
          onClose={hideDispatchModal}
          primaryBtnText="Confirm"
          primaryBtnProps={{
            loading: processing,
            disabled: processing,
          }}
          onPrimaryBtnClick={() => {
            handleUpdateShippingStatus({ key: BOOKING_UNIQUE_ID_KEY });
          }}
        />
      </div>
    </div>
  );
};

export default withComponentLibraryTheme(ListingCustomers);
