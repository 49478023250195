import React, { useCallback, useMemo } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import styles from "./attendeesPopup.module.css";
import ExlyTable from "common/Components/ExlyTable";
import {
  attendeesFilterConfig,
  attendeesValues,
  ATTENDEES_FOR_LISTING_RA_PROPS,
  getAttendeesColumnConfig,
  tabFilterConfig,
  tableChipsFormatter,
} from "./attendeesTableConfig";
import { useListController } from "react-admin";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { is_empty } from "utils/validations";
import { useNotifications, useToggleState } from "utils/hooks";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";
import { useHistory } from "react-router";
import {
  emptyModalStateProps,
  getBroadcastActions,
} from "../../ListCustomersTableConfig";
import { appendUrlParams } from "utils/urlUtils";
import { prepareRecipientList } from "../../../components/helpers";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import ExlyModal from "common/Components/ExlyModal";
import SessionStorageConstants from "constants/SessionStorage.constants";

const commonProps = { modalPaperClassName: styles.root, customFooter: <></> };

const AttendeesPopup = ({ listingUuid, open, onClose }) => {
  const { filterValues, loaded, data, selectedIds, onSelect } =
    useListController({
      ...ATTENDEES_FOR_LISTING_RA_PROPS,
      filterDefaultValues: {
        [attendeesValues.has_attended]: true,
      },
    });
  localStorage.setItem(
    SessionStorageConstants.PROMOTIONAL_LISTING,
    listingUuid
  );

  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();

  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const [isBroadcastModalActive, showBroadcastModal, hideBroadcastModal] =
    useToggleState(false);
  const { notify } = useNotifications();
  const isEmptySelectedIds = is_empty(selectedIds);

  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);

  const canBroadcastMessage = (canSendEmail || canSendWhatsapp) && !isEmpty;
  const sendWhatsappBroadcast = useCallback(() => {
    if (isEmptySelectedIds) return notify("No contact selected!", "error");

    const phoneNumberList = selectedIds.map(
      (i) => `${data[i]?.country_code}${data[i]?.phone_number}`
    );

    history.push(
      appendUrlParams("/whatsapp-broadcasts/select", {
        preselected_numbers: encodeURIComponent(phoneNumberList.join(",")),
      })
    );
  }, [selectedIds]);

  const sendEmail = useCallback(() => {
    if (isDesktop && isEmptySelectedIds)
      return notify("No contact selected!", "error");

    prepareRecipientList(data, selectedIds);
  }, [selectedIds, data]);

  const broadcastActions = useMemo(() => {
    return getBroadcastActions({
      sendEmail,
      sendWhatsappBroadcast,
    });
  }, [sendEmail, sendWhatsappBroadcast, selectedIds, data]);

  const footerProps = {
    primarybtn: "Send Message",
    primaryClick: showBroadcastModal,
    hidePrimarybtn: !canBroadcastMessage,
  };

  const bookingsTitle = useMemo(
    () => <div className={styles.bookingTitleWrapper}>Attendees </div>,
    []
  );

  const sendMessageButtonProps = {
    onClick: showBroadcastModal,
    disabled: isEmpty,
  };

  const columnConfig = useMemo(() => getAttendeesColumnConfig(), []);

  return (
    <ExlyModal
      open={open}
      modal_props={commonProps}
      onClose={onClose}
      title={bookingsTitle}
      mobile_modal_props={{
        paperClassName: styles.modalPaperUpsell,
        escapeKeyDownClose: true,
        ...commonProps,
      }}
    >
      <div className={styles.mobileModalDivWrapper}>
        <ExlyTable
          ra_props={{
            ...ATTENDEES_FOR_LISTING_RA_PROPS,
            filterDefaultValues: {
              [attendeesValues.has_attended]: true,
            },
          }}
          noExportButton
          layoutProps={{
            paddingDesktop: "0",
            layoutDesktopMargin: "20px 0px 20px 0px",
            layoutMobileMargin: "0",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
          }}
          showFiltersOnEmptyList={true}
          sendMessageButtonProps={sendMessageButtonProps}
          showSendMessageButton={isDesktop && canBroadcastMessage}
          renderPrimaryColumnRightNode={(record) => {
            return (
              <>
                {is_empty(record.phone_number) ? null : (
                  <WhatsAppButton
                    record={record}
                    source="phone_number"
                    countryCodeKey="country_code"
                  />
                )}
              </>
            );
          }}
          tabFilterConfig={tabFilterConfig}
          checkboxSelection
          selected={selectedIds}
          onRowSelected={onSelect}
          columnConfig={columnConfig}
          tableFilters={attendeesFilterConfig}
          hideAllTab={true}
          showSelectAllcheckBox={true}
          hasMobileFooter={true}
          tableChipsFormatter={tableChipsFormatter}
          footerProps={footerProps}
          borderedFields
          fieldsLeftPadded
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          primaryKey="id"
          {...emptyModalStateProps}
        />
        {canBroadcastMessage && isBroadcastModalActive && (
          <BroadcastModal
            open={isBroadcastModalActive}
            onClose={hideBroadcastModal}
            modal_props={{ title: "Send Message" }}
            mobile_modal_props={{ header: "Select Broadcast medium" }}
            broadcastActions={broadcastActions}
          />
        )}
      </div>
    </ExlyModal>
  );
};

export default React.memo(AttendeesPopup);
