import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { fetchInstalmentHistory } from "../../ManageBookings.apiCalls";
import { useEffect, useState } from "react";
import { logError } from "utils/error";

const useCustomerInstalmentPaymentHistory = ({
  listingUuid,
  transactionUuid,
}) => {
  const { notify } = useNotifications();

  const [isLoading, startLoading, stopLoading] = useToggleState(false);
  const [instalmentsPaymentHistory, setInstalmentsPaymentHistory] = useState(
    []
  );

  const handleFetchInstalmentPaymentHistory = () => {
    if (!listingUuid || !transactionUuid) return;

    startLoading();

    fetchInstalmentHistory({ listingUuid, transactionUuid })
      .then(setInstalmentsPaymentHistory)
      .catch((error) => {
        notify(error?.message, notification_color_keys.error);
        logError({
          error,
          when: "calling fetchInstalmentHistory",
          occuredAt:
            "src/ui/pages/customers/ManageBookings/modules/hooks/useCustomerInstalmentPaymentHistory.js",
          severity: "blocker",
        });
      })
      .finally(stopLoading);
  };

  useEffect(() => {
    handleFetchInstalmentPaymentHistory();
  }, [listingUuid, transactionUuid]);

  return { isLoading, instalmentsPaymentHistory };
};

export default useCustomerInstalmentPaymentHistory;
