import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import React from "react";
import { EmailField } from "react-admin";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";

// Enum definitions
export const upsellPurchaseValues = {
  has_purchased_upsell: "has_purchased_upsell",
  not_purchased_upsell: "not_purchased_upsell",
};

export const table_col_keys = {
  name: "customer_name",
  email: "customer_email",
  phone_number: "phone_number",
};

export const tabFilterConfig = [
  {
    label: "Purchased Upsell",
    source: upsellPurchaseValues.has_purchased_upsell,
    filterValue: true,
    value: upsellPurchaseValues.has_purchased_upsell,
  },
  {
    label: "Didn’t purchase upsell",
    source: upsellPurchaseValues.has_purchased_upsell,
    filterValue: false,
    value: upsellPurchaseValues.not_purchased_upsell,
  },
];

export const getUpsellColumnConfig = () => [
  {
    field: table_col_keys.name,
    headerName: "Name",
    valueFormatter: (record) => (
      <NameField record={record} source={table_col_keys.name} />
    ),
    avatar: true,
    sortable: false,
    width: "200px",
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 25,
    fixed: "left",
    isPrimary: true,
    isDrawerTitle: true,
    emptyField: "N/A",
  },
  {
    field: table_col_keys.email,
    headerName: "Customer Email",
    valueFormatter: (record) => (
      <EmailField record={record} source={table_col_keys.email} />
    ),
  },
  {
    field: table_col_keys.phone_number,
    headerName: "Phone Number",
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="phone_number"
        countryCodeKey="country_code"
      />
    ),
  },
];

export const tableChipsFormatter = (filter) => {
  switch (filter) {
    case upsellPurchaseValues.has_purchased_upsell:
      return null;

    default:
      return undefined;
  }
};

const pathName = app_route_ids[app_route_keys.upsell_details];

export const UPSELL_FOR_LISTING_RA_PROPS = {
  basePath: pathName,
  history: {
    location: {
      pathname: pathName,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: pathName,
    search: "",
    hash: "",
  },
  match: {
    path: pathName,
    url: pathName,
    isExact: true,
    params: {},
  },

  resource: pathName,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const UpsellfilterConfig = [
  {
    source: "guest_name",
    placeholder: "Name",
    type: "input",
    disableContains: true,
  },
];
