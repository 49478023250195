import React from "react";
import {
  WhatsAppPhoneField,
  DateTimeField,
} from "common/Components/TableFields/TableFields";
import {
  ViewSlotsField,
  NextDueDateValue,
  ShareActions,
  ScheduleSession,
} from "../ListingCustomers/components/TableFields/TableFields";
import { LinkField } from "webpage-leads/components/CustomColumns";
import { api } from "data";
import { FIELD_DATA, FORM_DATA, listing_api_keys } from "../constants";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { MerchandiseShipmentMethod } from "schedule-v2/commonPages/Components/MerchandiseShipping/data";
import constants from "constants/constants";
import { variantDetailsFormKeys } from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/Modals/VariantDetailsModal/data";
import { promotional_upsells, upsellTypeMap } from "../constants";
import { is_empty } from "utils/validations";
import { upsellTypeOptions } from "constants/upsellConstants";
import { TableStatusField } from "ui/pages/schedule/BookedSession/components/TableFields/TableFields";
import { UTMInfoField } from "common/form/UTMInfoField";
import { timezoneOptions } from "ui/modules/modules.utils";
import { ORDER_PAYMENT_TYPE_LABELS } from "schedule-v2/constants";
import { PAYMENT_PLAN_TYPE_OPTIONS } from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import MerchandiseVariantsChips from "features/Crm/modules/Bookings/modules/MerchandiseVariantChips/MerchandiseVariantsChips";
import MerchandiseDeliveryAddressField from "features/Crm/modules/Bookings/modules/MerchandiseDeliveryAddressField/MerchandiseDeliveryAddressField";
import MerchandiseShippingStatusDropdown from "features/Crm/modules/Bookings/modules/MerchandiseShippingStatusDropdown/MerchandiseShippingStatusDropdown";
import { shipping_status_options } from "features/Crm/modules/Bookings/modules/MerchandiseShippingStatusDropdown/MerchandiseShippingStatus.constants";
import EmailField from "ui/Fields/EmailField";
import { NameField } from "../../AllCustomers/components/TableColumns/TableColumns";
import { listing_customer_country_code } from "features/Listings/Listings.constants";
import { see_responses_link_text } from "features/Crm/Crm.constants";
import { BatchField } from "features/Crm/modules/Bookings/components/BatchField/BatchField";
import CustomerStatus from "features/Crm/modules/Bookings/modules/CustomerStatus/CustomerStatus";
import { getTablePriceFieldValue } from "features/Common/modules/Price/utils/Price.utils";
import { TELEGRAM_SPECIFIC_COLUMNS } from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/constants/TelegramBookings.constants";
import { TelegramGroupState } from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/modules/TelegramGroupState/TelegramGroupState";

//@DEV(muskan) todo not moving this to feature folder as This config will be moved to BE in CRM R4
export const masterFormData = {
  name: "Name",
  timezone: "Timezone",
  email: "Email",
  phone: "Phone Number",
  shipping_status: "Shipping Status",
  offering_name: "Offering Name",
  booked_on: "Booked On",
  slots_booked: "Slot(s) Booked",
  session_booked: "Session Booked",
  batch: "Batch",
  upsell_type: "Promotion Type",
  clear: "Clear Filter",
  start_date: "Start Date",
  end_date: "End Date",
  plan_name: "Plan Name",
  week: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  course_end_type: ["", "week", "month", "session"],
  type_cta: [""],
  booked_on_or_after: {
    field: "created_at__start_range",
    placeholder: "Booked On or After",
  },
  price: {
    field: "currency_price",
    placeholder: "Price Paid",
  },
  min_price: {
    placeholder: "Minimum Price",
    source: "min_price",
  },
  max_price: {
    placeholder: "Maximum Price",
    source: "max_price",
  },
  payment_type: {
    field: "payment_type",
    source: "payment_type",
    placeholder: "Payment Type",
  },
  payment_debit_mode: {
    field: "payment_debit_mode",
    source: "payment_debit_mode",
    placeholder: "Debit Type",
  },
  installments: {
    field: "paid_installments",
    placeholder: "Instalments paid",
  },
  total_installments: {
    field: "total_instalments",
    placeholder: "Instalments paid",
  },
  utm_info: {
    field: "utm_info",
    placeholder: "UTM info",
  },
  utm_source: {
    source: "utm_source",
    placeholder: "UTM Source",
  },
  utm_medium: {
    field: "utm_info",
    placeholder: "UTM medium",
    source: "utm_medium",
  },
  utm_campaign: {
    field: "utm_info",
    placeholder: "UTM campaign",
    source: "utm_campaign",
  },
  quantity: {
    field: "quantity",
    placeholder: "Quantity",
  },
  merchandise_variants: {
    field: "variants",
    placeholder: "Variant Purchased",
  },
  delivery_address: {
    field: "customer_address",
    placeholder: "Delivery Address",
  },
  responses: {
    field: "answer_count",
    placeholder: "Responses",
    linkText: see_responses_link_text,
  },
  due_date: {
    field: "dueDate",
    placeholder: "Next Due Date",
  },
};

export const masterFieldData = {
  purchased_offering_name: "purchased_offering_name",
  purchased_plan_name: "purchased_plan_name",
  upsell_name: "source_upsell_offering_title",
  timezone: "customer_timezone",
  shipping_status: "dispatch_status",
  name: "customer_name",
  email: "customer_email",
  phone: "customer_phone",
  slots_booked: "slots_booked",
  slots_booked_filter_key: "session_start_datetime",
  booked_on: "booked_on",
  session_booked: "slot_start_datetime",
  session_booked_filter_key: "slot_uuid",
  batch: "class_group_uuid",
  booked_on_filter_key: "created_at__start_range",
};
const getResponsesField = ({ handleAnswersClick }) => {
  return [
    {
      field: masterFormData.responses.field,
      headerName: masterFormData.responses.placeholder,
      valueFormatter: (record) =>
        record.answer_count ? (
          <LinkField
            record={record}
            source={masterFormData.responses.field}
            linkText={masterFormData.responses.linkText}
            onClick={handleAnswersClick}
          />
        ) : (
          <div className="mr-12">-</div>
        ),
      noWrap: true,
      sortable: false,
    },
  ];
};

const getDueDateColumnField = ({ isClass }) => {
  return [
    {
      field: masterFormData.due_date.field,
      headerName: masterFormData.due_date.placeholder,
      valueFormatter: (record) => (
        <NextDueDateValue
          record={record}
          source={masterFormData.due_date.field}
        />
      ),
      sortable: false,
      hidden: !isClass,
      hiddenDesktopColumn: !isClass,
    },
  ];
};
const getTablePriceField = ({ isTransactionsHidden, entityData }) => {
  if (!is_empty(entityData)) return [];
  return [
    {
      field: masterFormData.price.field,
      headerName: masterFormData.price.placeholder,
      // TODO: replace price logic all over tool to keep it consistent
      valueFormatter: (record) =>
        getTablePriceFieldValue(record[masterFormData.price.field]),
      sortable: false,
      hidden: isTransactionsHidden,
      hiddenDesktopColumn: isTransactionsHidden,
      noWrap: true,
    },
  ];
};

const bookedOnField = [
  {
    field: masterFieldData.booked_on,
    headerName: masterFormData.booked_on,
    valueFormatter: (record) => (
      <DateTimeField record={record} source={masterFieldData.booked_on} />
    ),
    sortable: false,
    noWrap: true,
  },
];
const utmInfoFields = [
  {
    field: masterFormData.utm_info.field,
    headerName: masterFormData.utm_info.placeholder,
    valueFormatter: (record) =>
      !is_empty(record.utm_info) ? <UTMInfoField record={record} /> : null,
    width: "400px",
    hidden: false,
    hiddenDesktopColumn: false,
  },
];
const promotionFields = [
  {
    field: listing_api_keys.UPSELL_TYPE,
    headerName: promotional_upsells.upsell_type,
    valueFormatter: (record) => {
      if (upsellTypeMap[record.upsell_type])
        return upsellTypeMap[record.upsell_type];
      else return "-";
    },
    noWrap: true,
    sortable: false,
  },
  {
    field: masterFieldData.upsell_name,
    headerName: promotional_upsells.source_upsell_offering_name,
    valueFormatter: (record) => record.source_upsell_offering_title || "-",
    noWrap: true,
    sortable: false,
  },
];

const getTableBasicDetails = ({
  handleBookingNavigationToCustomer,
  isCustomerDetailsHidden,
}) => {
  return [
    {
      field: masterFieldData.name,
      headerName: masterFormData.name,
      valueFormatter: (record) => (
        <NameField
          record={record}
          source={masterFieldData.name}
          onClick={handleBookingNavigationToCustomer}
        />
      ),
      emptyField: "-",
      isPrimary: true,
      sortable: false,
      ellipsis: true,
      avatar: true,
      fixed: "left",
      columnClassName: "word_break",
      width: "200px",
      isDrawerTitle: true,
    },
    {
      field: masterFieldData.email,
      headerName: masterFormData.email,
      valueFormatter: (record) =>
        record[masterFieldData.email] ? (
          <EmailField record={record} source={masterFieldData.email} />
        ) : (
          "-"
        ),
      sortable: false,
      width: "200px",
      columnClassName: "word_break",
      hidden: isCustomerDetailsHidden,
      hiddenDesktopColumn: isCustomerDetailsHidden,
    },
    {
      field: masterFieldData.phone,
      headerName: masterFormData.phone,
      valueFormatter: (record) => (
        <WhatsAppPhoneField
          record={record}
          source={masterFieldData.phone}
          countryCodeKey={listing_customer_country_code}
        />
      ),
      sortable: false,
      noWrap: true,
      hidden: isCustomerDetailsHidden,
      hiddenDesktopColumn: isCustomerDetailsHidden,
    },
  ];
};
const getTablePlanFields = ({
  isCustomerDetailsHidden,
  entityData,
  listingData,
}) => {
  return is_empty(entityData)
    ? [
        {
          field: masterFieldData.purchased_plan_name,
          headerName: masterFormData.plan_name,
          valueFormatter: (record) => record.purchased_plan_name || "-",
          noWrap: true,
          sortable: false,
          hidden: !listingData?.has_dropdown_plans,
          hiddenDesktopColumn: !listingData?.has_dropdown_plans,
        },
      ]
    : [
        {
          field: masterFieldData.purchased_offering_name,
          headerName: masterFormData.offering_name,
          sortable: false,
          width: "200px",
          columnClassName: "word_break",
          hidden: isCustomerDetailsHidden,
          hiddenDesktopColumn: isCustomerDetailsHidden,
        },
      ];
};
const getMerchandiseColumns = ({
  isMerchandise,
  variantsData,
  handleShowShippingStatusUpdateModal,
  listingData,
  entityData,
}) => {
  if (!is_empty(entityData)) return [];
  return isMerchandise
    ? [
        {
          field: masterFormData.merchandise_variants.field,
          headerName: masterFormData.merchandise_variants.placeholder,
          valueFormatter: (record) => (
            <MerchandiseVariantsChips variants={record.variants} />
          ),
          sortable: false,
          noWrap: true,
          hidden: !variantsData,
          hiddenDesktopColumn: !variantsData,
        },
        {
          field: masterFormData.quantity.field,
          headerName: masterFormData.quantity.placeholder,
          valueFormatter: (record) => record.quantity,
          sortable: false,
          noWrap: true,
        },
        {
          field: masterFieldData.shipping_status,
          headerName: masterFormData.shipping_status,
          valueFormatter: (record) => (
            <MerchandiseShippingStatusDropdown
              record={record}
              onChange={() => handleShowShippingStatusUpdateModal(record)}
              hide={
                listingData?.shipping_details?.shipment_method !==
                  MerchandiseShipmentMethod.MANUAL ||
                record.dispatch_status === constants.dispatchStatus.DELIVERED
              }
            />
          ),
          sortable: false,
          noWrap: true,
        },
        {
          field: masterFormData.delivery_address.field,
          headerName: masterFormData.delivery_address.placeholder,
          valueFormatter: (record) => (
            <MerchandiseDeliveryAddressField
              address={record[masterFormData.delivery_address.field]}
            />
          ),
          sortable: false,
          width: "300px",
        },
      ]
    : [];
};
const getSessionBookedField = ({ isWebinar, entityData }) => {
  if (!is_empty(entityData)) return [];
  return [
    {
      field: masterFieldData.session_booked,
      headerName: masterFormData.session_booked,
      valueFormatter: (record) => (
        <DateTimeField
          record={record}
          source={masterFieldData.session_booked}
        />
      ),
      sortable: false,
      hidden: !isWebinar,
      hiddenDesktopColumn: !isWebinar,
      noWrap: true,
    },
  ];
};

const getSlotsBookedField = ({ isAppointment, showSlots, entityData }) => {
  if (!is_empty(entityData)) return [];
  return [
    {
      field: masterFieldData.slots_booked,
      headerName: masterFormData.slots_booked,
      valueFormatter: (record) => (
        <ViewSlotsField
          showSlots={showSlots}
          record={record}
          source={masterFieldData.slots_booked}
        />
      ),
      sortable: false,
      hidden: !isAppointment,
      hiddenDesktopColumn: !isAppointment,
      noWrap: true,
    },
  ];
};

const getTableTimezoneField = ({ isListingScheduleType, entityData }) => {
  return [
    {
      field: masterFieldData.timezone,
      headerName: masterFormData.timezone,
      emptyField: "-",
      sortable: false,
      hidden: !isListingScheduleType && is_empty(entityData),
      hiddenDesktopColumn: !isListingScheduleType && is_empty(entityData),
    },
  ];
};

const getSessionOptions = ({ listingData, entityData }) => {
  if (!is_empty(entityData)) return [];
  const batchOptions = listingData?.slots?.map((slot, index) => {
    return {
      ...slot,
      label: `Session ${index + 1}`,
      value: slot?.uuid,
    };
  });
  return batchOptions;
};
const getBatchField = ({ isClass, listingData, entityData }) => {
  if (!is_empty(entityData)) return [];
  return [
    {
      field: masterFieldData.batch,
      headerName: masterFormData.batch,
      valueFormatter: (record) => (
        <BatchField
          listingData={listingData}
          record={record}
          source={masterFieldData.batch}
        />
      ),
      sortable: false,
      hidden: !isClass,
      hiddenDesktopColumn: !isClass,
    },
  ];
};

const getTablePartPaymentFields = ({
  isInstallmentPaymentType,
  entityData,
}) => {
  if (!is_empty(entityData)) return [];
  return isInstallmentPaymentType
    ? [
        {
          field: masterFormData.payment_type.field,
          headerName: masterFormData.payment_type.placeholder,
          valueFormatter: (record) => {
            const { payment_type } = record;
            return payment_type ? ORDER_PAYMENT_TYPE_LABELS[payment_type] : "-";
          },
          noWrap: true,
        },
        {
          field: masterFormData.installments.field,
          headerName: masterFormData.installments.placeholder,
          valueFormatter: (record) => {
            return `${record[masterFormData.installments.field] || 0} of ${
              record[masterFormData.total_installments.field] || 0
            } instalments`;
          },
          noWrap: true,
        },
      ]
    : [];
};

export const masterGetTableConfigDefault = ({
  isClass,
  isAppointment,
  listing_data,
  entity_data,
  showSlots,
  handleAnswersClick,
  handleShowShippingStatusUpdateModal,
  sendEmail,
  isCustomerDetailsHidden,
  isWebinar,
  customColumnsToRender,
  isMerchandise,
  isTelegramCommunity,
  isTransactionsHidden,
  isListingScheduleType,
  isInstallmentPaymentType,
  variantsData,
  handleBookingNavigationToCustomer,
  isQPP,
  isRecordedContent,
}) => [
  ...getTableBasicDetails({
    isCustomerDetailsHidden,
    handleBookingNavigationToCustomer,
  }),
  ...getTablePlanFields({
    isCustomerDetailsHidden,
    entityData: entity_data,
    listingData: listing_data,
  }),
  ...getSlotsBookedField({ isAppointment, showSlots, entityData: entity_data }),
  ...getMerchandiseColumns({
    isMerchandise,
    variantsData,
    handleShowShippingStatusUpdateModal,
    listingData: listing_data,
    entityData: entity_data,
  }),
  ...getSessionBookedField({ isWebinar, entityData: entity_data }),
  ...getTablePriceField({ isTransactionsHidden, entityData: entity_data }),
  ...bookedOnField,
  // @dev - telegram community listing specific column - Group State
  ...(isTelegramCommunity
    ? [
        {
          field: TELEGRAM_SPECIFIC_COLUMNS.group_state.source,
          headerName: TELEGRAM_SPECIFIC_COLUMNS.group_state.label,
          valueFormatter: (record) => (
            <TelegramGroupState
              group_state={record[TELEGRAM_SPECIFIC_COLUMNS.group_state.source]}
            />
          ),
          noWrap: true,
        },
      ]
    : []),
  ...getTablePartPaymentFields({
    isInstallmentPaymentType,
    entityData: entity_data,
  }),
  ...promotionFields,
  ...getBatchField({
    isClass,
    listingData: listing_data,
    entityData: entity_data,
  }),
  ...(is_empty(entity_data) ? utmInfoFields : []),
  ...getTableTimezoneField({ isListingScheduleType, entityData: entity_data }),
  ...getResponsesField({ handleAnswersClick }),
  ...getDueDateColumnField({ isClass }),
  /** append custom columns to be shown in table */
  ...customColumnsToRender,
  ...(isClass
    ? [
        {
          field: FIELD_DATA.status,
          headerName: FORM_DATA.subs_renewal,
          valueFormatter: (record) => (
            <NextDueDateValue record={record} source={FIELD_DATA.status} />
          ),
          sortable: false,
          hidden: isQPP || isRecordedContent || is_empty(entity_data),
          hiddenDesktopColumn: is_empty(entity_data),
        },
      ]
    : [
        {
          field: FIELD_DATA.status,
          headerName: FORM_DATA.status,
          valueFormatter: (record) => <TableStatusField record={record} />,
          sortable: false,
          hidden: isQPP || isRecordedContent || is_empty(entity_data),
          hiddenDesktopColumn: is_empty(entity_data),
        },
      ]),
  {
    field: "",
    headerName: "Actions",
    valueFormatter: (record) => (
      <ShareActions record={record} sendEmail={sendEmail} />
    ),
    sortable: false,
    hidden: !isClass,
    hiddenDesktopColumn: !isClass,
  },
];
/** This functions runs when availability is not decided */
export const masterGetTableConfigNotDecided = ({
  isClass,
  isAppointment,
  listing_data,
  showSlots,
  handleAnswersClick,
  sendEmail,
  isCustomerDetailsHidden,
  customColumnsToRender,
  isQPP,
  isRecordedContent,
  isTransactionsHidden,
  isListingScheduleType,
  entity_data,
  handleBookingNavigationToCustomer,
}) => [
  ...getTableBasicDetails({
    isCustomerDetailsHidden,
    handleBookingNavigationToCustomer,
  }),
  ...getTablePlanFields({
    isCustomerDetailsHidden,
    entityData: entity_data,
    listingData: listing_data,
  }),
  ...getSlotsBookedField({ isAppointment, showSlots, entityData: entity_data }),
  ...getTablePriceField({ isTransactionsHidden, entityData: entity_data }),
  ...bookedOnField,
  ...getBatchField({
    isClass,
    listingData: listing_data,
    entityData: entity_data,
  }),
  ...promotionFields,
  ...(is_empty(entity_data) ? utmInfoFields : []),
  ...getTableTimezoneField({ isListingScheduleType, entityData: entity_data }),
  ...getResponsesField({ handleAnswersClick }),
  ...getDueDateColumnField({ isClass }),
  {
    field: "status",
    headerName: "Subscription Renewal",
    valueFormatter: (record) => (
      <NextDueDateValue record={record} source="status" />
    ),
    sortable: false,
    hidden: !isClass || isQPP || isRecordedContent,
    hiddenDesktopColumn: !isClass,
  },
  {
    field: "",
    headerName: "Actions",
    valueFormatter: (record) => (
      <ShareActions record={record} sendEmail={sendEmail} />
    ),
    sortable: false,
    hidden: !isClass,
    hiddenDesktopColumn: !isClass,
  },
  {
    field: "",
    headerName: "Schedule Session",
    sortable: false,
    valueFormatter: (record) => (
      <ScheduleSession record={record} listing_data={listing_data} />
    ),
    width: "150px",
    columnClassName: "word_break",
  },
  /** append custom columns to be shown in table */
  ...customColumnsToRender,
  {
    field: "",
    headerName: "Customer Status",
    sortable: false,
    width: "180px",
    valueFormatter: (record) => <CustomerStatus record={record} />,
    columnClassName: "word_break",
    fixed: "right",
  },
];

export const masterGetFilterConfig = ({
  isClass,
  variantsData,
  customFiltersToAppend,
  isListingScheduleType,
  entity_data,
  isInstallmentPaymentType,
  isWebinar,
  isAppointment,
  isMerchandise,
  listingData,
}) => {
  const isPlan = !is_empty(entity_data);

  const basicFilterConfig = [
    {
      source: listing_api_keys.NAME,
      placeholder: masterFormData.name,
      type: "input",
      disableContains: true,
    },
    {
      source: isClass
        ? listing_api_keys.SUBSCRIBED_USER_EMAIL
        : listing_api_keys.USER_EMAIL,
      placeholder: masterFormData.email,
      type: "input",
      disableContains: true,
    },
    {
      source: isClass
        ? listing_api_keys.SUBSCRIBED_USER_PHONE
        : listing_api_keys.USER_PHONE,
      placeholder: masterFormData.phone,
      type: "input",
      disableContains: true,
    },
  ];

  const promotionFilterConfig = [
    {
      source: listing_api_keys.UPSELL_TYPE,
      placeholder: promotional_upsells.upsell_type,
      options: upsellTypeOptions,
      type: "dropdown",
      disableContains: true,
    },
    {
      source: listing_api_keys.PURCHASED_FROM_OFFERING,
      placeholder: promotional_upsells.source_upsell_offering_name,
      type: "input",
      disableContains: true,
    },
  ];

  const planConfig = [
    ...basicFilterConfig,
    {
      source: isClass
        ? listing_api_keys.TRANSACTION_START_DATE
        : listing_api_keys.START_DATE,
      placeholder: masterFormData.start_date,
      type: "date",
      disableContains: true,
    },
    {
      source: isClass
        ? listing_api_keys.TRANSACTION_END_DATE
        : listing_api_keys.END_DATE,
      placeholder: masterFormData.end_date,
      type: "date",
      disableContains: true,
    },
    ...promotionFilterConfig,
    ...customFiltersToAppend,
  ];
  // return from here in case of plan : manage bookings
  if (isPlan) return planConfig;

  const variantOptions = variantsData?.map(
    ({ [variantDetailsFormKeys.VARIANT_MAP]: variantMap, uuid }) => ({
      label: Object.values(variantMap).join(" | "),
      value: uuid,
    })
  );
  const merchandiseVariantsFilter = variantsData
    ? [
        {
          source: listing_api_keys.VARIANT,
          placeholder: masterFormData.merchandise_variants.placeholder,
          type: "dropdown",
          options: variantOptions,
        },
      ]
    : [];

  const config = [
    ...basicFilterConfig,
    ...(listingData?.has_dropdown_plans
      ? [
          {
            source: masterFieldData.purchased_plan_name,
            placeholder: masterFormData.plan_name,
            type: "input",
            disableContains: true,
          },
        ]
      : []),
    ...(isAppointment
      ? [
          {
            source: masterFieldData.slots_booked_filter_key,
            type: "date",
            placeholder: masterFormData.slots_booked,
          },
        ]
      : []),
    ...(isWebinar
      ? [
          {
            source: masterFieldData.session_booked_filter_key,
            type: "dropdown",
            options: getSessionOptions({ listingData }),
            placeholder: masterFormData.session_booked,
          },
        ]
      : []),
    ...(isMerchandise
      ? [
          ...merchandiseVariantsFilter,
          {
            source: masterFormData.quantity.field,
            type: "input",
            placeholder: masterFormData.quantity.placeholder,
            disableContains: true,
          },
          {
            source: masterFieldData.shipping_status,
            type: "dropdown",
            options: shipping_status_options,
            placeholder: masterFormData.shipping_status,
          },
          {
            source: masterFormData.delivery_address.field,
            type: "input",
            placeholder: masterFormData.delivery_address.placeholder,
            disableContains: true,
          },
        ]
      : []),
    {
      source: masterFormData.min_price.source,
      placeholder: masterFormData.min_price.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: masterFormData.max_price.source,
      placeholder: masterFormData.max_price.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: masterFormData.booked_on_or_after.field,
      placeholder: masterFormData.booked_on_or_after.placeholder,
      type: "date",
    },
    ...(isInstallmentPaymentType
      ? [
          {
            source: masterFormData.payment_type.source,
            placeholder: masterFormData.payment_type.placeholder,
            options: PAYMENT_PLAN_TYPE_OPTIONS,
            type: "dropdown",
            disableContains: true,
          },
          {
            source: masterFormData.installments.field,
            placeholder: masterFormData.installments.placeholder,
            type: "input",
            disableContains: true,
          },
        ]
      : []),
    ...promotionFilterConfig,
    {
      source: masterFormData.utm_source.source,
      placeholder: masterFormData.utm_source.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: masterFormData.utm_medium.source,
      placeholder: masterFormData.utm_medium.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: masterFormData.utm_campaign.source,
      placeholder: masterFormData.utm_campaign.placeholder,
      type: "input",
      disableContains: true,
    },
    ...(isListingScheduleType
      ? [
          {
            source: masterFieldData.timezone,
            placeholder: masterFormData.timezone,
            type: "dropdown",
            options: timezoneOptions,
            disableContains: true,
          },
        ]
      : []),
    /** append custom columns to be shown in filter options */
    ...customFiltersToAppend,
  ];

  return config;
};

export const masterTableChipsFormatter = ({
  filter,
  value,
  variantsData,
  customChipsToFormat,
  orgMembers,
  listingData,
}) => {
  const columnToFilter = customChipsToFormat?.find(
    (chipToFormat) => chipToFormat.columnSlug === filter
  );
  if (columnToFilter) {
    const staff = orgMembers?.find((item) => item.id == value);
    return `${columnToFilter?.columnName}: ${staff?.label}`;
  } else {
    switch (filter) {
      case listing_api_keys.NAME:
        return `${masterFormData.name}: ${value}`;
      case listing_api_keys.SUBSCRIBED_USER_EMAIL:
      case listing_api_keys.USER_EMAIL:
        return `${masterFormData.email}: ${value}`;
      case listing_api_keys.SUBSCRIBED_USER_PHONE:
      case listing_api_keys.USER_PHONE:
        return `${masterFormData.phone}: ${value}`;
      case listing_api_keys.UPSELL_TYPE: {
        return `${promotional_upsells.upsell_type}: ${
          upsellTypeOptions.find((item) => item.value == value).label
        }`;
      }
      case listing_api_keys.OFFERING_PURCHASED: {
        return `${promotional_upsells.source_upsell_offering_title}: ${value}`;
      }
      case listing_api_keys.PURCHASED_FROM_OFFERING: {
        return `${promotional_upsells.source_upsell_offering_name}: ${value}`;
      }
      case masterFieldData.booked_on_filter_key:
        return `${masterFormData.booked_on}: ${value}`;
      case listing_api_keys.TRANSACTION_START_DATE:
      case listing_api_keys.START_DATE:
        return `${masterFormData.start_date}: ${value}`;
      case listing_api_keys.TRANSACTION_END_DATE:
      case listing_api_keys.END_DATE:
        return `${masterFormData.end_date}: ${value}`;
      case masterFormData.quantity.field:
        return `${masterFormData.quantity.placeholder}: ${value}`;
      case masterFieldData.shipping_status: {
        const shippingStatus = shipping_status_options?.find(
          (item) => item.value === parseInt(value)
        )?.label;
        return `${masterFormData.shipping_status}: ${shippingStatus}`;
      }
      case masterFormData.delivery_address.field:
        return `${masterFormData.delivery_address.placeholder}: ${value}`;
      case masterFieldData.slots_booked_filter_key:
        return `${masterFormData.slots_booked}: ${value}`;
      case masterFieldData.session_booked_filter_key: {
        const options = getSessionOptions({ listingData });
        const label = options?.find((item) => item.value === value)?.label;
        return `${masterFormData.session_booked}: ${label || ""}`;
      }
      case `${masterFormData.utm_source.source}`:
        return `${masterFormData.utm_source.placeholder}: ${value}`;
      case `${masterFormData.utm_medium.source}`:
        return `${masterFormData.utm_medium.placeholder}: ${value}`;
      case `${masterFormData.utm_campaign.source}`:
        return `${masterFormData.utm_campaign.placeholder}: ${value}`;
      case masterFormData.min_price.source:
        return `${masterFormData.min_price.placeholder}: ${value}`;
      case masterFormData.max_price.source:
        return `${masterFormData.max_price.placeholder}: ${value}`;
      case `${masterFieldData.purchased_plan_name}`:
        return `${masterFormData.plan_name}: ${value}`;
      case masterFieldData.timezone:
        return `${masterFormData.timezone}: ${value}`;
      case listing_api_keys.VARIANT: {
        const variantMap =
          variantsData?.find((variant) => variant.uuid === value)?.[
            variantDetailsFormKeys.VARIANT_MAP
          ] || {};
        const label = Object.values(variantMap).join(" | ");
        return `Variant: ${label}`;
      }
      case masterFormData.installments.field:
        return `${masterFormData.installments.placeholder}: ${value}`;
      default:
        return undefined;
    }
  }
};

export const MASTER_LISTING_CUSTOMERS_PROPS = {
  basePath: `/${api.customer_list}`,
  history: {
    length: 6,
    action: "POP",
    location: {
      pathname: `/${api.customer_list}`,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: `/${api.customer_list}`,
    search: "",
    hash: "",
  },
  match: {
    path: `/${api.customer_list}`,
    url: `/${api.customer_list}`,
    isExact: true,
    params: {},
  },
  resource: `${api.customer_list}`,
  options: { label: `Offering Customers` },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const masterGetBroadcastActions = ({
  sendEmail,
  sendWhatsappBroadcast,
}) => [
  {
    title: "Send Custom Email",
    description: "Send a Custom Email to the selected leads",
    onClick: sendEmail,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
    ),
  },
  {
    title: "Send WhatsApp Broadcast",
    description: "Send a WhatsApp Broadcast to the selected leads.",
    onClick: sendWhatsappBroadcast,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
    ),
  },
];

export const spreadArrayAfterHeaderName = (
  tableConfig,
  additionalArray,
  headerName
) => {
  const index = tableConfig.findIndex((item) => item.headerName === headerName);

  if (index === -1) {
    // If the given headerName is not found, return the original array
    return tableConfig;
  }

  return [
    ...tableConfig.slice(0, index + 1),
    ...additionalArray,
    ...tableConfig.slice(index + 1),
  ];
};

export const spreadArrayAtIndex = (config, additionalArray, index) => {
  return [
    ...config.slice(0, index), // Spread the items before the desired index
    ...additionalArray, // Spread the additional array
    ...config.slice(index), // Spread the items after the desired index
  ];
};
