import {
  masterFormData,
  masterGetTableConfigDefault,
  masterGetTableConfigNotDecided,
  masterGetFilterConfig,
  MASTER_LISTING_CUSTOMERS_PROPS,
  masterGetBroadcastActions,
} from "../components/MasterTableConfig";
import EmptyListIcon from "assets/vectors/infoModal/search.svg";
import styles from "../../Style.module.css";
export const form_data = { ...masterFormData };

export const getTableConfigDefault = (props) => {
  return masterGetTableConfigDefault(props);
};

export const getTableConfigNotDecided = (props) => {
  return masterGetTableConfigNotDecided(props);
};

export const getFilterConfig = (props) => {
  return masterGetFilterConfig(props);
};

export const LISTING_CUSTOMERS_PROPS = { ...MASTER_LISTING_CUSTOMERS_PROPS };

export const getBroadcastActions = (props) => {
  return masterGetBroadcastActions(props);
};

export const emptyModalStateProps = {
  content_title: "No results found",
  empty_list_icon: EmptyListIcon,
  classes: { content_wrapper: styles.listing_empty_wrapper },
};
