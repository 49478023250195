import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  header: {
    height: "59.5px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
  },
  name_wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 500,
  },
  header_icons: {
    display: "flex",
    gap: "25.43px",
  },
  cross_icon_wrapper: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  modal_paper: {
    maxWidth: "666px",
    width: "666px",
  },
  container: {
    padding: "6.5px 0px 18px 24px",
    "& $row + $row": {
      borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    },
  },
  loaderWrapper: {
    padding: "24px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    padding: "10px 24px 10px 0",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    color: "rgba(0, 0, 0, 0.85)",
    "@media (max-width: 768px)": {
      fontSize: "14px",
    },
    fontSize: "16px",
  },
  row_title: {
    flexShrink: 0,
    lineHeight: "20px",
    fontWeight: 500,
  },
  row_value: {
    lineHeight: "19px",
    fontWeight: 400,
    wordBreak: "break-word",
    textAlign: "right",
  },
  remarks_row: {
    flexDirection: "column",
    gap: "0px",
    paddingBottom: "0",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  lineField: {
    fontSize: "16px",
    lineHeight: "19px",
  },
  action: {
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
