import { PRICING_MODE_KEYS, schedule_types_ids } from "constants/schedule";
import {
  UPDATE_CUSTOMER_SUBSCRIPTION_API,
  form_data,
} from "./NewCustomers.constants";
import { logError } from "utils/error";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { format, parse } from "date-fns";
import {
  DEFAULT_DATE_MONTH_YEAR_FORMAT,
  DEFAULT_YEAR_MONTH_DATE_FORMAT,
} from "constants/date-fns/dateTime.constant";
import { is_empty } from "utils/validations";
import { isFlexibleOneTime } from "utils/listing/listingUtils";

export const getSubscriptionStartDateHeaderName = (listing_data = {}) => {
  if (isFlexibleOneTime(listing_data)) {
    return form_data.class_start_date;
  }
  return form_data.subscription_start_date;
};

export const getSubscriptionEndDateHeaderName = (listing_data = {}) => {
  if (isFlexibleOneTime(listing_data)) {
    return form_data.class_end_date;
  }
  return form_data.next_due_date;
};

export const getStatusHeaderName = (listing_data) => {
  if (
    listing_data?.type === schedule_types_ids.rolling_class &&
    listing_data?.pricing_mode === PRICING_MODE_KEYS.monthly
  ) {
    return form_data.subscription_status;
  }
  return form_data.status;
};

/**
 * to change validity of a booking
 * @param {*}
 * @returns void: updates subscription dates of the record
 */
export const updateExpiry = async ({
  record,
  newEndDate,
  newStartDate = null,
  onSuccess,
  onFailure,
}) => {
  if (is_empty(record) || is_empty(newEndDate)) return;
  try {
    const date = parse(newEndDate, DEFAULT_DATE_MONTH_YEAR_FORMAT, new Date());
    const subscription_end_date = format(date, DEFAULT_YEAR_MONTH_DATE_FORMAT);
    let payload = {
      subscription_end_date,
      transaction_uuid: record?.transaction_uuid,
    };

    if (!is_empty(newStartDate)) {
      const date = parse(
        newStartDate,
        DEFAULT_DATE_MONTH_YEAR_FORMAT,
        new Date()
      );
      const subscription_start_date = format(
        date,
        DEFAULT_YEAR_MONTH_DATE_FORMAT
      );
      payload.subscription_start_date = subscription_start_date;
    }

    const response = await dataProvider.custom_request(
      UPDATE_CUSTOMER_SUBSCRIPTION_API,
      apiMethods.POST,
      payload
    );
    if (onSuccess) onSuccess(response);
  } catch (error) {
    logError({
      error,
      when: "on updateExpiry",
      occuredAt:
        "src/ui/pages/customers/ManageBookings/SubscriptionCustomers/SubscriptionCustomers.utils.js",
    });
    if (onFailure) onFailure(error);
  }
};
