import constants from "constants/constants";
import moment from "moment";
import { getAuthData, getUserTimezone } from "utils/AuthUtil";
import { isValidEmail, is_empty } from "utils/validations";
import { api, dataProvider } from "data";
import { parseAddedVariants } from "schedule-v2/Merchandise/utils";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { DEFAULT_UPSELLING_STATS_NUMBER, upsellMapping } from "../constants";
import { getShortNotationOfLargeNumbers } from "utils/string";
import { offering_availability_statuses } from "features/Listings/Listings.constants";
import { HTTP_STATUS_ERROR_CODES } from "features/Common/modules/Api/constants/statusCodes";

export const setAppointmentsData = (
  data,
  set_able_to_add,
  set_appointments
) => {
  let temp = [];
  if (is_empty(data.slots)) return set_able_to_add(false);

  for (let i = 0; i < data.slots.length; i++) {
    let item = data.slots[i];
    let check = temp.filter(
      (slot) =>
        slot.date ===
        moment(item.start_time)
          .tz(getUserTimezone())
          .format(
            data.interval_type === 2 ? "dddd" : constants.displayDateYearFormat //TODO: Check what 2 represents and map it in future
          )
    );
    if (is_empty(check)) {
      temp.push({
        date: moment(item.start_time)
          .tz(getUserTimezone())
          .format(
            data.interval_type === 2 ? "dddd" : constants.displayDateYearFormat //TODO: Check what 2 represents and map it in future
          ),
        slots: [
          `${moment(item.start_time)
            .tz(getUserTimezone())
            .format("hh:mm A")} to ${moment(item.end_time)
            .tz(getUserTimezone())
            .format("hh:mm A")}`,
        ],
        index: temp.length,
      });
    } else {
      if (
        !temp[check[0].index].slots.includes(
          `${moment(item.start_time)
            .tz(getUserTimezone())
            .format("hh:mm A")} to ${moment(item.end_time)
            .tz(getUserTimezone())
            .format("hh:mm A")}`
        )
      ) {
        temp[check[0].index].slots.push(
          `${moment(item.start_time)
            .tz(getUserTimezone())
            .format("hh:mm A")} to ${moment(item.end_time)
            .tz(getUserTimezone())
            .format("hh:mm A")}`
        );
      }
    }
  }
  set_appointments(temp);
};

export const fetchListingDetails = async (
  listing_customers_id,
  set_able_to_add,
  getIfSlotsAvailable,
  listing_customers_type,
  set_appointments,
  setVariantsData,
  set_listing_data,
  notify,
  set_loading
) => {
  try {
    const data = await dataProvider.custom_request(
      `${api.get_listing_details}/${listing_customers_id}`,
      "GET",
      { show_classes: "True", unique_customers: "True" }
    );

    if (data.status === 200) {
      const { event } = data.data;
      const isPlanManageBooking = !is_empty(
        sessionStorage.getItem(SessionStorageConstants.ENTITY_DATA)
      );
      set_able_to_add(
        (event.status === offering_availability_statuses.live &&
          getIfSlotsAvailable(event) &&
          event.is_active) ||
          isPlanManageBooking
      );

      if (
        listing_customers_type ===
        constants.scheduleTypesId.one_on_one_appointment
      ) {
        setAppointmentsData(event, set_able_to_add, set_appointments);
      }

      await set_listing_data(event);

      const { uuid: listingUuid, type } = event;

      if (type === constants.scheduleTypesId.merchandise) {
        const variantsResponse = await dataProvider.custom_request(
          api.get_listing_variants,
          "GET",
          { listing_uuid: listingUuid }
        );

        if (variantsResponse.status === 200) {
          const { data: variantsData } = variantsResponse;
          setVariantsData(parseAddedVariants(variantsData));
        }
      }
    }
  } catch (err) {
    console.log(
      "Something went wrong while fetching customer listing details.",
      err
    );
    if (
      !is_empty(err.body) &&
      !is_empty(
        err.body.message &&
          err?.body?.status != HTTP_STATUS_ERROR_CODES.BAD_REQUEST
      )
    ) {
      notify(err.body.message, "error");
    }
  } finally {
    set_loading(false);
  }
};

export const prepareRecipientList = (data, selectedIds) => {
  let recipient_list = [];
  let email_list = [];
  let emailInvalid = false;

  if (!is_empty(selectedIds)) {
    for (let id of selectedIds) {
      let value = data[id];
      let check = email_list.filter(
        (item) => item.email === value.customer_email
      );
      if (!is_empty(check) || is_empty(value.customer_email)) continue;

      if (!isValidEmail(value.customer_email)) emailInvalid = true;
      email_list.push({
        name: value.customer_name,
        email: value.customer_email,
      });
    }
  } else {
    for (let id in data) {
      let value = data[id];
      let check = email_list.filter(
        (item) => item.email === value.customer_email
      );
      if (!is_empty(check) || is_empty(value.customer_email)) continue;

      if (!isValidEmail(value.customer_email)) emailInvalid = true;
      email_list.push({
        name: value.customer_name,
        email: value.customer_email,
      });
    }
  }

  let recipient_list_local = {
    key: "extra",
    emails: email_list,
    display_name: "Selected Customers",
    email_check: email_list,
    invalid: emailInvalid,
  };

  recipient_list.push(recipient_list_local);
  sessionStorage.setItem(
    SessionStorageConstants.RECIPIENT_LIST,
    JSON.stringify(recipient_list)
  );
  window.location.href = `${window.location.origin}/#/ComposeEmail`;
};

export const listingsCustomersType = parseInt(
  sessionStorage.getItem(SessionStorageConstants.LISTING_CUSTOMER_TYPE)
);

export const getFunnelStats = async (listing_uuid) => {
  try {
    if (is_empty(listing_uuid)) return;
    let auth = getAuthData();
    const response = await dataProvider.custom_request(
      `${api.order_bumps.analytics_funnel}/${listing_uuid}`
    );
    if (response.status === 200) {
      const mappedData = {};

      const { data } = response;

      // we don't show the attendees card if zoom attendance is not enabled
      if (!data?.is_zoom_attendance_enabled)
        delete upsellMapping.total_attendees;

      for (const key in upsellMapping) {
        if (key in data) {
          let statsNumber = data[key] || DEFAULT_UPSELLING_STATS_NUMBER;

          if (key === upsellMapping.total_lifetime_value.key) {
            statsNumber = `${
              auth.creator_currency === constants.inr
                ? constants.inr_currency
                : constants.usd_currency
            } ${getShortNotationOfLargeNumbers(statsNumber)}`;
          }

          const mappedObject = {
            Icon: upsellMapping[key].Icon,
            onClick: upsellMapping[key].onClick,
            statsNumber: statsNumber,
            className: upsellMapping[key].className,
            title: upsellMapping[key].title,
            color: upsellMapping[key].color,
            tooltipString: upsellMapping[key].tooltipString,
          };

          mappedData[key] = mappedObject;
        }
      }
      const mappedDataArray = Object.values(mappedData);
      return mappedDataArray;
    }
  } catch (err) {
    console.log("Something went wrong while fetching customer slots.", err);
  }
};

export const getPlanFunnelStats = async (plan_uuid) => {
  try {
    if (is_empty(plan_uuid)) return;
    let auth = getAuthData();
    const response = await dataProvider.custom_request(
      `${api.order_bumps.plan_analytics_funnel}/${plan_uuid}`
    );
    if (response.status === 200) {
      const mappedData = {};

      const { data } = response;

      // we don't show the attendees card if zoom attendance is not enabled
      if (!data?.is_zoom_attendance_enabled)
        delete upsellMapping.total_attendees;
      // we won't show the cards with value as "N/A"
      Object.keys(data).forEach((key) => {
        if (data[key] === "N/A") delete data[key];
      });

      for (const key in upsellMapping) {
        if (key in data) {
          let statsNumber = data[key] || DEFAULT_UPSELLING_STATS_NUMBER;

          if (key === upsellMapping.total_lifetime_value.key) {
            statsNumber = `${
              auth.creator_currency === constants.inr
                ? constants.inr_currency
                : constants.usd_currency
            } ${getShortNotationOfLargeNumbers(statsNumber)}`;
          }

          const mappedObject = {
            Icon: upsellMapping[key].Icon,
            onClick: upsellMapping[key].onClick,
            statsNumber: statsNumber,
            className: upsellMapping[key].className,
            title: upsellMapping[key].title,
            color: upsellMapping[key].color,
            tooltipString: upsellMapping[key].tooltipString,
          };

          mappedData[key] = mappedObject;
        }
      }
      const mappedDataArray = Object.values(mappedData);
      return mappedDataArray;
    }
  } catch (err) {
    console.log("Something went wrong while fetching customer slots.", err);
  }
};
